import { useDrag, useDrop } from "react-dnd";
import dummyImage from "../../assets/images/album.png";
const DragAbleImage = ({ maincroppedImage, moveImage, index ,videoImg}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "DIV", // Define the type property for the draggable item
    item: { index }, // Pass the index of the image as part of the item
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "DIV",
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const dropIndex = index; // Use the index of the current image
      moveImage(dragIndex, dropIndex);
    },
  });
 
  return (
    <div
      ref={(node) => drag(drop(node)) }
      style={{ opacity: isDragging ? 0.5 : 1 }}
    
    >
      {/* Render your images here */}
      <a href={maincroppedImage} target="_blank">
      <img
        src={maincroppedImage.endsWith(".mp4")
        ? videoImg
        : maincroppedImage} // Assuming maincroppedImage is a file path
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // Prevents looping
          currentTarget.src = dummyImage; // Replace with the path to your dummy image
        }}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      </a>
    </div>
  );
};

export default DragAbleImage;
