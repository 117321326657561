import React, { useState ,useEffect} from "react";
import MultipleFeatureDropDown from "./MultipleFeatureDropDown";
import { toast } from "react-toastify";

function SignatureFeaturesTab({
  handleChange,
  values,
  setFieldValue,
  setValues,
  field='',
  signatureFeaturesData=[],
  heading="",
  labelHeading="",
  placeholder="",
}) {
  const [featureDropdowns, setFeatureDropdowns] = useState([]);
  const [FeatureValues, setFeatureValues] = useState([]);
  const { name, value } = field;

  const [dropdownCount, setDropdownCount] = useState(
    signatureFeaturesData.length
  );
  if (dropdownCount === 0) {
    setDropdownCount(1);
  } else {
  }

  const [selectedValues, setSelectedValues] = useState(signatureFeaturesData);

  useEffect(() => {
    if (Array.isArray(selectedValues) && selectedValues.length > 0) {
      const filterSelectedValues = selectedValues?.filter(
        (item) => item !== 0 && item !== ""
      );
      setFieldValue(name, filterSelectedValues);
    }
  }, [selectedValues, setFieldValue, name]);

  const handleDropdownChange = (index, value) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = value;
    setSelectedValues(newSelectedValues);
  };

  const handleAddClick = () => {
    
    if(selectedValues[dropdownCount-1] === undefined){
      toast.dismiss();
      toast.error(
        "Please select a value in the current input box before adding a new one."
      );
    }
    else if (selectedValues[dropdownCount - 1] !== "") {
      setDropdownCount((prevCount) => prevCount + 1);
      setSelectedValues((prevValues) => [...prevValues, ""]);
    } else {
      toast.dismiss();
      toast.error(
        "Please select a value in the current input box before adding a new one."
      );
    }
  };

  const handleRemoveClick = (index) => {
    setDropdownCount((prevCount) => prevCount - 1);
    setSelectedValues((prevValues) => prevValues.filter((_, i) => i !== index));
  };

  const handleChangeFeatureChange = (event, dropdownKey) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        features: {
          ...prevValues.features,
          [dropdownKey]: event.target.value,
        },
      };
    });

  };
  
  const handleAddFeature = (index) => {
    const newDropdownKey = index.toString();
    setFeatureDropdowns((prevDropdowns) => [
      ...prevDropdowns,
      {
        key: Math.random() * 1000,
        options: "",

        onDelete: () => handleRemoveFeatureDropdown(newDropdownKey),
      },
    ]);
  };


  const handleRemoveFeatureDropdown = (dropdown) => {
    const parentDiv = document.querySelector(
      `.row.position-relative[data-key="${dropdown}"]`
    );
    if (parentDiv) {
      parentDiv.remove();
    }

    const data = values.features;
    const keyToRemove = dropdown; // You might get the key dynamically or hardcode it

    // Check if the key exists before removing
    if (data.hasOwnProperty(keyToRemove)) {
      delete data[keyToRemove];
      
    } else {
    }
  };
  return (
    <>
      <div className="edit-mein-info">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="top-header-edit">
            <h5>{heading}</h5>
          </div>
        </div>
        {/* <!-- /top info --> */}
        <div className="row mt-lg-5 mt-md-4 mt-3">
          {/* <div className="col-md-12">
            <label className="form-label">Add Feature</label>
            <div className="bed-row d-flex flex-wrap align-items-end">
              <div className="bed-add-row">
                <input
                  type="text"
                  className="form-control py-3 shadow-none px-4"
                  placeholder="Enter feature"
                  name="features"
                  //   value={values.features['parentFeature']}
                  onChange={(e) =>
                    handleChangeFeatureChange(e, "parentFeature")
                  }
                />
              </div>
              <div className="add-more-blocks text-end">
                <a
                  onClick={handleAddFeature}
                  className="btn btn-primary btn-theme fw-semibold text-uppercase"
                >
                  <i className="fa fa-plus"></i>
                </a>
              </div>
            </div>
          </div> */}
           <div className="col-md-12">
            <label className="form-label">{labelHeading}</label>
            {Array.from({ length: dropdownCount }, (_, index) => (
              <div
                className="bed-row d-flex flex-wrap align-items-end mb-4"
                key={index}
              >
                <div className="bed-add-row">
                  <input
                    type="text"
                    className="form-control py-3 shadow-none px-4"
                    placeholder={placeholder}
                    name={`features_${index}`}
                    id={`features_${index}`}
                    value={selectedValues[index]}
                    onChange={(e) =>
                      handleDropdownChange(index, e.target.value)
                    }
                  />
                </div>
                {index === dropdownCount - 1 && (
                  <div className="add-more-blocks text-end mb-2">
                    <a
                      className="btn btn-primary btn-theme fw-semibold text-uppercase"
                      onClick={handleAddClick}
                    >
                      <i className="fa fa-plus"></i>
                    </a>
                  </div>
                )}
                {index !== dropdownCount - 1 && (
                  <div className="add-more-blocks text-end mb-2">
                    <a
                      className="btn   btn-outline-danger text-uppercase"
                      onClick={() => handleRemoveClick(index)}
                    >
                      <i className="fa fa-minus"></i>
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
          {featureDropdowns.map((dropdown, index) => (
            <>
              <MultipleFeatureDropDown
                dropdown={dropdown}
                index={index}
                values={values}
                handleChangeFeatureChange={handleChangeFeatureChange}
                handleRemoveFeatureDropdown={() =>
                  handleRemoveFeatureDropdown(dropdown.key)
                }
                FeatureValues={FeatureValues}
                setFeatureValues={setFeatureValues}
              />
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default SignatureFeaturesTab;
