import React, { useEffect } from "react";
import { TabScreen } from "react-tabs-scrollable";
import "../../../node_modules/react-tabs-scrollable/dist/rts.css";
import DemographicTabItem from "./DemographicTabItem";
import { getAgeAndCountryData } from "../dataService/DataService";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { BsFullscreen } from "react-icons/bs";
import CardSpinner from "./CardSpinner";

function DemographicTabs({ websiteId, rangeType, startDate, endDate }) {
  const [selectedCountry, setSelectedCountry] = React.useState("");

  // Fetch age and country data using react-query
  const ageAndCountryData = useQuery(
    [`getAgeAndCountryData-${websiteId}`, rangeType, startDate, endDate],
    () => getAgeAndCountryData(rangeType, startDate, endDate, selectedCountry),
    {
      staleTime: 86400000, // 1 day
      cacheTime: 86400000, // 1 day
    }
  );

  const topCountryData = ageAndCountryData?.data?.topCountries;
  const userAgeBracketData = ageAndCountryData?.data?.ageBracketData;
  const userGenderData = ageAndCountryData?.data?.genderData;

  // Set the first country as default when topCountryData is available
  useEffect(() => {
    if (topCountryData?.length > 0 && !selectedCountry) {
      setSelectedCountry(topCountryData[0]); // Default to first country
    }
  }, [topCountryData, selectedCountry]);

  // Update selected countries and toggle between selections
  const handleClick = (country) => {
    setSelectedCountry((prevSelectedCountry) => {
      const selectedCountriesArray = prevSelectedCountry
        .split(",")
        .filter((c) => c);

      if (selectedCountriesArray.includes(country)) {
        // Remove selected country if it's already in the list
        const updatedCountries = selectedCountriesArray.filter(
          (c) => c !== country
        );
        if (updatedCountries.length === 0) {
          return prevSelectedCountry; // Keep the last country selected
        }
        return updatedCountries.join(",");
      } else {
        // Add selected country to the list
        return [...selectedCountriesArray, country].join(",");
      }
    });
  };

  // Sum active users by age bracket for selected countries
  const summedAgeBracketData = userAgeBracketData
    ?.filter((item) => selectedCountry.includes(item.country))
    .reduce((acc, item) => {
      item.ageBrackets.forEach((ageBracket) => {
        const existingBracket = acc.find(
          (bracket) => bracket.ageBracket === ageBracket.ageBracket
        );
        if (existingBracket) {
          existingBracket.activeUsers += ageBracket.activeUsers;
        } else {
          acc.push({ ...ageBracket });
        }
      });
      return acc;
    }, []);

  // Sum active users by gender for selected countries
  const summedGenderData = userGenderData
    ?.filter((item) => selectedCountry.includes(item.country))
    .reduce((acc, item) => {
      item.genderData.forEach((genderItem) => {
        const existingGender = acc.find(
          (gender) => gender.gender === genderItem.gender
        );
        if (existingGender) {
          existingGender.activeUsers += genderItem.activeUsers;
        } else {
          acc.push({ ...genderItem });
        }
      });
      return acc;
    }, []);

  return (
    <>
      {ageAndCountryData?.isLoading ? (
        <CardSpinner />
      ) : (
        <>
          <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
            <h6 className="fw-semibold text-capitalize mb-0">
              Demographic Overview for Top 3 Countries
            </h6>
            <div className="full-view d-none">
              <Link to="">
                <BsFullscreen className="h6 mb-0" />
              </Link>
            </div>
          </div>
          <div className="card-body pt-0 px-0">
            <div className="overview-tabs"></div>
            <div className="tabs-header filter-btns-group default-data-tab-btns d-flex justify-content-end cntry-tab-btns">
              {topCountryData?.map((country, index) => (
                <button
                  key={index}
                  className={`country-button rts___tab btn btn-outline fw-semibold h-auto ${
                    selectedCountry?.includes(country) ? "active" : ""
                  }`}
                  onClick={() => handleClick(country)}
                >
                  {country}
                </button>
              ))}
            </div>

            <div className="tabs-body">
              <TabScreen>
                <DemographicTabItem
                  selectedUserAgeBracketData={summedAgeBracketData}
                  selectedUserGenderData={summedGenderData}
                />
              </TabScreen>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DemographicTabs;
