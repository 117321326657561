import React from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import dragImg from "../../assets/images/drag.svg";
import { CSS } from '@dnd-kit/utilities';
const SortablePageDropDown = ({
    id,
    subsection,
    handleSelect,
    handleShowEditSubSectionModal,
    handleShowModal
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useDraggable({
        id: id.toString()
    });
    const dragStyle = {
        transition,
        transform: transform
            ? `translate3d(${transform.x}px, ${transform.y}px, 0) scale(1, 1)`
            : 'scale(1, 1)',
        cursor: 'pointer'
    };
    const { isOver, setNodeRef: setDropNodeRef } = useDroppable({
        id: id.toString(),
        data: { id },
        disabled: false,
    });

    return (
        <div
            className="dropdown-item d-flex align-items-center"
            ref={setNodeRef}
            style={dragStyle}

        >
            <div onClick={(e) => e.preventDefault()} ref={setDropNodeRef}>
                <button
                    ref={setNodeRef}
                    className="drop-drag-btn border-0 bg-transparent"
                    {...attributes}
                    {...listeners}
                >
                    <img src={dragImg} alt="" title="" />
                </button>
            </div>
            <span
                className="select-text"
                onClick={(event) => handleSelect(subsection.page_sub_section_id, event)}
            >
                {subsection.sub_section_title}
            </span>
            <span className="ms-auto d-flex dropdown-icons">
                <FontAwesomeIcon
                    icon={faPencil}
                    onClick={() => handleShowEditSubSectionModal(subsection)}
                    className="edit-icon me-3"
                />
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={() => handleShowModal(subsection)}
                    className="delete-icon text-danger"
                />
            </span>
        </div>
    );
};

export default SortablePageDropDown;
