import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ScriptModal } from "./ScriptModal";
import {  useState } from "react";
import { MdDragIndicator } from "react-icons/md";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Modal, Button } from "react-bootstrap";
import {
  deleteScript,
  changeSortingIndex,
} from "../../dataService/DataService";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
export const HandelScripts = ({
  heading = "",
  scriptArray = [],
  setterScript,
  location_id,
}) => {
  const client = useQueryClient();
  const [scriptModal, setScriptModal] = useState(false);
  const sortingIndexMutation = useMutation(changeSortingIndex, {
    onSuccess: () => {
      toast.dismiss();
      toast.success("sorting index changed successfully");
      client.invalidateQueries("scripts");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.message || "something went wrong");
    },
    onMutate: () => {
      toast.dismiss();
      toast.loading("updating sorting ");
    },
  });
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setterScript((items) => {
        const oldIndex = items.findIndex((item) => item.id == active.id);
        const newIndex = items.findIndex((item) => item.id == over.id);
        const oldData = items;
        const newData = arrayMove(items, oldIndex, newIndex);
        sortingIndexMutation.mutate({ playLoad: { newData, oldData } });
        return newData;
      });
    }
  }
  return (
    <>
      <div className="test">{heading}</div>
      {scriptArray?.length > 0 ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={scriptArray}
            strategy={verticalListSortingStrategy}
          >
            {scriptArray?.map((item) => (
              <SortableItem key={item.id} id={item.id} item={item} />
            ))}
          </SortableContext>
          <button
            onClick={() => {
              setScriptModal(true);
            }}
            className="btn btn-primary text-uppercase my-2"
          >
            add script
          </button>
        </DndContext>
      ) : (
        <>
          <button
            onClick={() => {
              setScriptModal(true);
            }}
            className=" btn btn-primary text-uppercase my-2"
          >
            add script
          </button>
        </>
      )}
      {scriptModal && (
        <ScriptModal
          modal={scriptModal}
          setModal={setScriptModal}
          location_id={location_id}
        />
      )}
    </>
  );
};

export function SortableItem({ id, item }) {
  const client = useQueryClient();
  const [scriptModal, setScriptModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteMutation = useMutation(deleteScript, {
    onSuccess: () => {
      toast.dismiss();
      toast.success("script deleted successfully ");
      setDeleteModal(false);
      client.invalidateQueries("scripts");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.message || "something went wrong");
    },
  });

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
      transition: {
        duration: 200, // milliseconds
        easing: "cubic-bezier(0.55, 1, 0.7, 1)",
      },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handelDelete = () => {
    deleteMutation.mutate({ id });
  };



  return (
    <>
      <div style={style} className="d-flex justify-content-between w-25">
        <div {...attributes} {...listeners} ref={setNodeRef}>
          <MdDragIndicator />
        </div>
        <div>{item.name}</div>
        <div className="">
          <button
            className="btn "
            onClick={() => {
              setScriptModal(true);
            }}
          >
            <i className="fa fa-pencil " aria-hidden="true"></i>
          </button>
          <button
            className="btn "
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            <i className="fa fa-trash " aria-hidden="true"></i>
          </button>
        </div>
      </div>
      {scriptModal && (
        <ScriptModal
          modal={scriptModal}
          setModal={setScriptModal}
          initialData={item}
          action="Edit Script"
        />
      )}
      {deleteModal && (
        <Modal
          show={deleteModal}
          onHide={() => {
            setDeleteModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete - {item?.name}?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteMutation.isLoading ? (
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <p>
                Once you delete this data, you won't be able to recover it. Are
                you sure you want to go ahead?
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="text-uppercase"
              onClick={() => setDeleteModal(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="btn-danger text-uppercase"
              onClick={() => handelDelete()}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
