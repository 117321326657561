import React from 'react'

const CardSpinner = () => {
  return (
    <div className="d-flex justify-content-center align-items-center py-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
  )
}

export default CardSpinner