import React, { useEffect, useState } from "react";
import { getAllBedType } from "../../services/service";

import { useQuery } from "react-query";
import { toast } from "react-toastify";
import OccupancyDropDown from "./OccupancyDropDown";
import BedTypes from "./BedTypes";
export default function SpecificationsTabEdit({
  field,
  props,
  handleChange,
  values,
  setFieldValue,
  setValues,
  initialBedTypeData = [],
  errors,

  error,
  touched,
  initialValues,
  duplicateIndexes,
  setDuplicateIndexes,
  areaRef,
  bedDataRef
}) {
  const { name, value } = field;

  const [dropdownCount, setDropdownCount] = useState(initialBedTypeData.length);
  const [selectedValues, setSelectedValues] = useState(initialBedTypeData);
  useEffect(() => {
    if (Array.isArray(selectedValues) && selectedValues.length > 0) {
      const filterSelectedValues = selectedValues?.filter(
        (item) => item !== 0 && item !== ""
      );
      setFieldValue(name, filterSelectedValues);
    }
  }, [selectedValues, setFieldValue, name]);

  const handleAddClick = () => {
    if (selectedValues[dropdownCount - 1] !== "") {
      setDropdownCount((prevCount) => prevCount + 1);
      setSelectedValues((prevValues) => [...prevValues, ""]);
    } else {
      toast.dismiss();
      toast.error(
        "Please select a value in the current dropdown before adding a new one."
      );
    }
  };

  const handleRemoveClick = (index) => {
    setDropdownCount((prevCount) => prevCount - 1);
    setSelectedValues((prevValues) => prevValues.filter((_, i) => i !== index));
  };
  const handleDropdownChangeTwo = (index, value) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = +value;
    setSelectedValues(newSelectedValues);
  };
  const handleRadioChange = (e, setFieldValue) => {
    const newValue = e.target.value;
    if (newValue === "no") {
      setFieldValue("poolSize", "0");
    }
  };

  const [dropdowns, setDropdowns] = useState([]);
  let AllBedType = useQuery("getAllBedType", getAllBedType);
  const handleChangeBedTypeChange = (event, dropdownKey) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        bedTypeId: {
          ...prevValues.bedTypeId,
          [dropdownKey]: event.target.value,
        },
      };
    });
  };

  ///adult count
  const adultIncrementBtn = (event) => {
    event.preventDefault();

    setValues({
      ...values,
      adultCount: +values.adultCount + 1,
    });
  };
  const adultIncrementBtn1 = (event) => {
    event.preventDefault();

    setValues({
      ...values,
      adultCount1: +values.adultCount1 + 1,
    });
  };
  const adultDecrementBtn = (e) => {
    e.preventDefault();

    if (values.adultCount > 1) {
      setValues({
        ...values,
        adultCount1: +values.adultCount1 - 1,
      });
    }
  };
  const adultDecrementBtn1 = (e) => {
    e.preventDefault();

    if (values.adultCount1 > 1) {
      setValues({
        ...values,
        adultCount1: +values.adultCount1 - 1,
      });
    }
  };
  /* End adult count */

  /* Start children count*/
  const childrenIncrement = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      childrenCount: +values.childrenCount + 1,
    });
  };
  const childrenIncrement1 = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      childrenCount1: +values.childrenCount1 + 1,
    });
  };
  const childrenDecrement = (event) => {
    event.preventDefault();
    if (values.childrenCount > 0) {
      setValues({
        ...values,
        childrenCount: +values.childrenCount - 1,
      });
    }
  };
  const childrenDecrement1 = (event) => {
    event.preventDefault();
    if (values.childrenCount1 > 0) {
      setValues({
        ...values,
        childrenCount1: +values.childrenCount1 - 1,
      });
    }
  };

  const handleAddDropdown = (index) => {
    const newDropdownKey = index.toString();
    setDropdowns((prevDropdowns) => [
      ...prevDropdowns,
      {
        key: Math.random() * 1000,
        options: "",

        onDelete: () => handleRemoveDropdown(newDropdownKey),
      },
    ]);
  };

  const handleRemoveDropdown = (dropdown) => {
    const parentDiv = document.querySelector(
      `added-specifications[data-key="${dropdown}"]`
    );
    if (parentDiv) {
      parentDiv.remove();
    }

    const data = values.bedTypeId;
    const keyToRemove = dropdown; // You might get the key dynamically or hardcode it

    // Check if the key exists before removing
    if (data.hasOwnProperty(keyToRemove)) {
      delete data[keyToRemove];
      
    } else {
    }
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };
  const websiteCode = getCookie("websiteCode");

  return (
    <>
      <div className="edit-mein-info">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="top-header-edit">
            <h5>Specifications</h5>
          </div>
        </div>

        <div className="property-facilities mt-4 mb-4">
          <div className="occupancy-row">
            <div className="row">
              <div className="col-12">
                <label htmlFor="FacilityOne" className="form-label">
                  <i className="fa-solid fa-user me-2"></i> Occupancy{" "}
                  <span className="mandatory">*</span>
                </label>
              </div>
              <div className="col mb-4">
                <div className="facility-view specification-dropdown d-flex align-items-center">
                 
                </div>
              </div>
              <div className="facility-view specification-dropdown  align-items-center">
                <OccupancyDropDown
                  values={values}
                  initialValues={initialValues}
                  showDropdown={true}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  duplicateIndexes={duplicateIndexes}
                  setDuplicateIndexes={setDuplicateIndexes}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="">
                
                {/* {Array.from({ length: dropdownCount }, (_, index) => (
                  <div className="bed-row d-flex flex-wrap align-items-end">
                    <div className="bed-add-row d-flex flex-wrap w-100">
                      <div className="select-bed-col mb-4">
                        <select
                          className="form-control form-select py-3 shadow-none px-4"
                          name={`bedTypeId_${index}`}
                          id={`bedTypeId_${index}`}
                          value={selectedValues[index]}
                          onChange={(e) =>
                            handleDropdownChangeTwo(index, e.target.value)
                          }
                        >
                          <option value="">---Select---</option>
                          {AllBedType.data &&
                            AllBedType.data.data.map((option) => (
                              <option
                                key={option.bed_type_id}
                                value={option.bed_type_id}
                                data-key={"parent"} // Add data-key attribute
                              >
                                {option.bed_type}
                              </option>
                            ))}
                        </select>
                      </div>
                      {index === dropdownCount - 1 && (
                        <div className="add-more-blocks text-end mb-4">
                          <a
                            onClick={handleAddClick}
                            className="btn btn-primary btn-theme fw-semibold text-uppercase"
                          >
                            <i className="fa fa-plus"></i>
                          </a>
                        </div>
                      )}
                      {index !== dropdownCount - 1 && (
                        <div className="add-more-blocks text-end mb-4">
                          <a
                            onClick={() => handleRemoveClick(index)}
                            className="btn   btn-outline-danger text-uppercase"
                          >
                            <i className="fa fa-minus"></i>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {errors.bedTypeId && touched.bedTypeId ? (
                  <p className="form-error text-danger">{errors.bedTypeId}</p>
                ) : error.bedTypeId ? (
                  <p className="form-error text-danger">{error.bedTypeId}</p>
                ) : null} */}
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              
            </div>
            {/* {dropdowns.map((dropdown, index) => (
                <>
                  <MultipleBedDrpDown
                    dropdown={dropdown}
                    index={index}
                    props={props}
                    values={values}
                    handleChangeBedTypeChange={handleChangeBedTypeChange}
                    AllBedType={AllBedType}
                    handleRemoveDropdown={() =>
                      handleRemoveDropdown(dropdown.key)
                    }
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                  />
                </>
              ))} */}
            <BedTypes
              bedDataRef={bedDataRef}
              values={values}
              initialValues={initialValues}
              showDropdown={true}
              setFieldValue={setFieldValue}
              setValues={setValues}
              AllBedType={AllBedType}
              errors={errors}
              error={error}
              touched={touched}
            />
            <div className="col-md-6 mb-4">
              <div className="facility-view">
                <label htmlFor="FacilityOne" className="form-label">
                  <i className="fa-solid fa-maximize me-2"></i>Area {websiteCode==="SADARMANZIL"?"(sq. ft.)":"(sq. m.)"}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control py-3 shadow-none px-4"
                  placeholder="Area Size"
                  name="area"
                  id=""
                  value={values.area}
                  onChange={handleChange}
                  maxLength={20}
                  ref={areaRef}
                />
                {/* <!-- /facility-view --> */}
              </div>
              {errors.area && touched.area ? (
                <p className="form-error text-danger">{errors.area}</p>
              ) : error.area ? (
                <p className="form-error text-danger">{error.area}</p>
              ) : null}
            </div>

            <div className="col-md-12 mb-4">
              <div className="facility-view">
                <label htmlFor="FacilityOne" className="form-label">
                  <i className="fa-solid fa-person-swimming me-2"></i> Private
                  Pool {websiteCode==="SADARMANZIL"?"(sq. ft.)":"(sq. m.)"}
                </label>
                <div className="d-flex align-items-center">
                  <div className="checkboxes mb-3 mt-3">
                    <label htmlFor="PrivatePoolY">
                      <input
                        id="PrivatePoolY"
                        type="radio"
                        name="privatepool"
                        value="yes"
                        checked={
                          values.privatepool === "yes" || values.poolSize > 0
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleRadioChange(e, setFieldValue);
                        }}
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        id="PrivatePoolN"
                        type="radio"
                        name="privatepool"
                        value="no"
                        checked={
                          // values.privatepool === "" ||
                          values.privatepool === "no" ||
                          values.poolSize === "" ||
                          values.poolSize === "0"
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleRadioChange(e, setFieldValue);
                        }}
                      />{" "}
                      No
                    </label>
                  </div>
                  {/* <!-- /facility-view --> */}
                  {(values.privatepool === "yes" || values.poolSize > 0) && (
                    <div className="yes-input ms-4">
                      <input
                        type="text"
                        placeholder="Enter pool size"
                        className="form-control py-3 shadow-none px-4"
                        name="poolSize"
                        value={values.poolSize}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
