import React from "react";
import { useQuery } from "react-query";
import { getAllUserRoles, websiteList } from "../dataService/DataService";
function MultiDropDown({
  index,
  handleRemoveDropdown,
  formData1,
  assignedRoles,
  commonHandleChange,
  errors,
  touched,
  handleBlur,
  values,
  dropdownKey,
  error,
  filteredareadata,
  selectedWebsites,
}) {
  let AllUsersRoles = useQuery("getAllUserRoles", getAllUserRoles);

  let AllWebsiteList = useQuery("websiteList", websiteList);
  const updatedFormData = { ...formData1 };


const matchingWebsite = selectedWebsites.find(website => +website.index === +index);

let availableWebsites = [];

if (matchingWebsite) {
    // If there is a match, append both filteredareadata and matchingWebsite data
    availableWebsites = [...filteredareadata, matchingWebsite];
} else {
    // If there is no match, append only filteredareadata
    availableWebsites = [...filteredareadata];
}




  return (
    <>
      <div key={index} data-key={index} className="row position-relative">
        <div className="col-lg-4">
          <div className="mb-4">
            <label className="form-label" htmlFor={`websiteId_${index}`}>
              Website <span className="mandatory">*</span>
            </label>
            <select
              name={`websiteId`}
              id={`websiteId_${index}`}
              className="form-control form-select py-3 shadow-none px-4 areaservedstates"
              onChange={(e) => commonHandleChange(e, index, "website_id")}
              defaultValue={assignedRoles[index]?.website_id || ""}
              onBlur={handleBlur}
              value={values.websiteId}
            >
              <option value="">---Select---</option>
            
              {availableWebsites.map((option) => (
                option.website &&
                <option key={option.website_id} value={option.website_id}>
                  {option.website}
                </option>
              ))}
            </select>
            {updatedFormData[index] &&
            (!updatedFormData[index].website_id ||
              updatedFormData[index].website_id === "") ? (
              <p className="form-error text-danger">Please select website.</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="col-lg-4">
          <div className="mb-4">
            <label className="form-label" htmlFor={`UsersRoleId_${index}`}>
              User Roles <span className="mandatory">*</span>
            </label>
            <select
              name={`UsersRoleId`}
              id={`UsersRoleId_${index}`}
              className="form-control form-select py-3 shadow-none px-4"
              onChange={(e) => commonHandleChange(e, index, "user_role_id")}
              defaultValue={assignedRoles[index]?.user_role_id || ""}
              onBlur={handleBlur}
              value={values.UsersRoleId}
            >
              <option value="">---Select---</option>
              {AllUsersRoles &&
                AllUsersRoles.data &&
                AllUsersRoles.data.data.map((option) => (
                  <option key={option.user_role_id} value={option.user_role_id}>
                    {option.user_role}
                  </option>
                ))}
            </select>
            {updatedFormData[index] &&
            (!updatedFormData[index].user_role_id ||
              updatedFormData[index].user_role_id == "") ? (
              <p className="form-error text-danger">Please select user role.</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="col-lg-4">
          <div className="mb-4 add-remove-btn">
            <a
              type="button"
              onClick={() => handleRemoveDropdown(index)}
              className="btn btn-outline-danger"
            >
              <i className="fa fa-minus"></i>{" "}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default MultiDropDown;
