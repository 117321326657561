import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

function ImageTagModalPages({
    showModalImageTagModal,
    handleCloseImageTagModal,
    // pageSubSectionId,
    // rowNameData,
    // id,
    altTagData,
    setAltTagData,
    altTagId,
    initialImageAltTag
}) {
   
    const inputName = altTagId;
    const [inputValue, setInputValue] = useState("");

    // Function to handle input change
    const handleChange = (event) => {
        // const { value } = event.target;
        // setInputValue(value);
        setInputValue(event.target.value);
    };
    
    // Function to handle form submission
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     setAltTagData(prevData => ({
    //         ...prevData,
    //         [altTagId]: inputValue
    //     }));
        
    //     setInputValue("")
    //     handleCloseImageTagModal(true)
    // };
    const handleSubmit = (event) => {
        event.preventDefault();
    
        // Assuming altTagId and inputValue are defined elsewhere
        const newData = {
            id: altTagId,
            data: inputValue
        };
     // Check if the id already exists in the array
     const index = altTagData.findIndex(item => item.id === altTagId);
        if (index !== -1) {
            // If the id exists, update the data
            setAltTagData(prevData => {
                const updatedData = [...prevData];
                updatedData[index] = newData;
                return updatedData;
            });
        } else {
            // If the id doesn't exist, add the new data
            setAltTagData(prevData => [...prevData, newData]);
        }
        
       
        handleCloseImageTagModal(false);
    };


    const handleCloseModal = () => {
        setInputValue('');
        handleCloseImageTagModal(false) 
    };
    
    // Update the value if initialImageAltTag changes
    // useEffect(() => {
    //     if(initialImageAltTag){
    //         const filteredData = initialImageAltTag.filter(item => item.id === altTagId && item.data !== undefined);
    //         const getData = filteredData.map(item => item.data);
    //         setInputValue(getData);
    //     }
       
    // }, [initialImageAltTag,showModalImageTagModal]);

useEffect(() => {
    if (initialImageAltTag) {
        const filteredData = initialImageAltTag.find(item => item.id === altTagId);
        const getData = filteredData ? filteredData.data : '';
        setInputValue(getData);
    }
}, [showModalImageTagModal,altTagId]);



    return (
        <Modal show={showModalImageTagModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton className="px-5 py-4">
                <Modal.Title as="h1" className="fs-5">
                    Image Alt Tag
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5 py-5">
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor={inputName}>Tag</Form.Label>
                        <Form.Control
                            type="text"
                            id={inputName}
                            className="py-3 shadow-none px-4"
                            name={inputName}
                            value={inputValue}
                            onChange={handleChange}
                            maxLength={160}
                        />
                    </Form.Group>
                    <div className="text-end mt-5">
                        <Button
                            variant="outline-primary"
                            className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                            type="submit"
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ImageTagModalPages;
