import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import _debounce from "lodash/debounce";
const DraggableSubSection = ({
  subsection,
  index,
  handleShowEditSubSectionModal,
  handleShowModal,
  handleReorderSubSections,
  ItemTypes,
  changeSortingIndexMutation,
  deleteBtnShow,
  ChildDeleteBtnShow,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    //  "SUB_SECTION",

    item: { id: subsection.page_sub_section_id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

 
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover: _debounce((item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      handleReorderSubSections(dragIndex, hoverIndex);

      item.index = hoverIndex;

      changeSortingIndexMutation.mutate({
        id: item.id, // Assuming item.id is the page_sub_section_id
        newSortingIndex: hoverIndex + 1,
      });
    }, 150), // Adjust debounce delay as needed
  });

  return (
    <>
      {subsection.is_deletable ? (
        <div
          ref={(node) => drag(drop(node))}
          style={{ opacity: isDragging ? 0.5 : 1 }}
        >
          <li
            className="nav-item"
            role="presentation"
            key={`${subsection.page_sub_section_id}-tablink`}
          >
            <a
              className="nav-link w-100 position-relative"
              id={`TabLogoOne-tab-${subsection.page_sub_section_id}`}
              data-bs-toggle="tab"
              data-bs-target={`#TabLogoOne-tab-pane-${subsection.page_sub_section_id}`}
              type="button"
              role="tab"
              aria-controls={`TabLogoOne-tab-pane-${subsection.page_sub_section_id}`}
              aria-selected="false"
              tabIndex="-1"
            >
              {subsection.sub_section_title}

              {subsection.is_deletable ? (
                <span
                  className=" text-dark position-absolute end-0 top-0 h-100 d-flex align-items-center px-5"
                  onClick={() => handleShowEditSubSectionModal(subsection)}
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>
              ) : (
                ""
              )}

              {subsection.is_deletable ? (
                !deleteBtnShow ? (
                  ""
                ) : (
                  <span
                    className="delete-tab text-dark position-absolute end-0 top-0 h-100 d-flex align-items-center px-3"
                    onClick={() => handleShowModal(subsection)}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </span>
                )
              ) : (
                ""
              )}
            </a>
          </li>
        </div>
      ) : (
        <>
          <li
            className="nav-item"
            role="presentation"
            key={`${subsection.page_sub_section_id}-tablink`}
          >
            <a
              className="nav-link w-100 position-relative"
              id={`TabLogoOne-tab-${subsection.page_sub_section_id}`}
              data-bs-toggle="tab"
              data-bs-target={`#TabLogoOne-tab-pane-${subsection.page_sub_section_id}`}
              type="button"
              role="tab"
              aria-controls={`TabLogoOne-tab-pane-${subsection.page_sub_section_id}`}
              aria-selected="false"
              tabIndex="-1"
            >
              {subsection.sub_section_title}
              {subsection.is_deletable ? (
                <span
                  className=" text-dark position-absolute end-0 top-0 h-100 d-flex align-items-center px-5"
                  onClick={() => handleShowEditSubSectionModal(subsection)}
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>
              ) : (
                ""
              )}

              {subsection.is_deletable ? (
                <span
                  className="delete-tab text-dark position-absolute end-0 top-0 h-100 d-flex align-items-center px-3"
                  onClick={() => handleShowModal(subsection)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </span>
              ) : (
                ""
              )}
            </a>
          </li>
        </>
      )}
    </>
  );
};

export default DraggableSubSection;
