import React,{useEffect} from 'react';
import * as XLSX from 'xlsx';

const CSVFileDownload = ({ data="", filename="", headers="" ,excelDownload="",onClick}) => {
  useEffect(() => {
    if (excelDownload) {
      downloadExcel();
    }
  }, [excelDownload]);
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, filename || 'data.xlsx');
  };

  return (
    <></>
    // <button onClick={onClick} className="btn" style={{backgroundColor: '#1f2f3e'}}>
    //  <span className='text-white'> Download</span>
    // </button>
  );
};

export default CSVFileDownload;
