import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../dataService/DataService";


export const userRoleSchema = Yup.object({
    user_role: Yup.string().trim().required("Please enter user Role"),
});

const CreateUserRole = () => {
    const navigate = useNavigate();
    const [offerfile, setofferfile] = useState("");
    const [image, setImage] = useState(null);
    const [error, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [description, setdescription] = useState('');
    const [isadmin, setIsAdmin] = useState(false);
    const [issuperadmin, setIsSuperAdmin] = useState(false);

    const { id } = useParams();
    const handlenaviagte = () => {
        // Handle removing the image
        navigate("/manage-roles");
    };

    const getSingleUserRole = useQuery(
        ["SingleUserRole", id], // Using an array as the query key with offerId
        () => querydata.getSingleUserRole(id)
    );

    useEffect(() => {
        setValues({ ...initialValues })

    }, [getSingleUserRole.data])



    const initialValues = {
        user_role: getSingleUserRole && getSingleUserRole.data && getSingleUserRole.data.data && getSingleUserRole.data.data.user_role ? getSingleUserRole.data.data.user_role : "1",
        is_admin: getSingleUserRole && getSingleUserRole.data && getSingleUserRole.data.data && getSingleUserRole.data.data.is_admin ? getSingleUserRole.data.data.is_admin : false,
        // is_admin:true,
        is_super_admin: getSingleUserRole && getSingleUserRole.data && getSingleUserRole.data.data && getSingleUserRole.data.data.is_super_admin ? getSingleUserRole.data.data.is_super_admin : false,
        is_active: getSingleUserRole && getSingleUserRole.data && getSingleUserRole.data.data && getSingleUserRole.data.data.is_active ? getSingleUserRole.data.data.is_active : 1,
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, setValues } =
        useFormik({
            initialValues: initialValues,
            validationSchema: userRoleSchema,
            onSubmit: (values, action) => {


                addMutation.mutate({ id, values });
                //action.resetForm();
            },
        });
    //form post
    const addMutation = useMutation(querydata.updateUserRole, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);

            toast.dismiss();
            toast.success(data.message);
            navigate("/manage-roles");
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });

    if (!getSingleUserRole.data) {
        return <div>Loading....</div>
    }

    return (
        <>

            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav className="w-100" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link className="text-decoration-none" to="/manage-roles">Manage Roles</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit User Role</li>
                            </ol>
                        </nav>
                        <div className="left-header">
                            <h4 className="fw-normal m-0">User Role</h4>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-md-4 mt-3">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 fw-medium">Edit User Role</h5>
                                    <div className="right-actions d-none">
                                        <a href="#" className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2">Save Draft</a>
                                        <a href="#" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2">Preview</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="upload-file-wrapper">
                                        <form action="#" method="get">
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-6 mb-4">
                                                    <label className="form-label" htmlFor="Name">User Role</label>
                                                    <input name="user_role" value={values.user_role}
                                                        onChange={handleChange}
                                                        maxLength={50}
                                                        onBlur={handleBlur} id="user_role" className="form-control py-3 shadow-none px-4" />
                                                    {errors.user_role && touched.user_role ? (
                                                        <p className="form-error text-danger">
                                                            {errors.user_role}
                                                        </p>
                                                    ) : error.user_role ? (
                                                        <p className="form-error text-danger">
                                                            {error.user_role}
                                                        </p>
                                                    ) : null}
                                                </div>

                                                <div className="col-xl-6 col-lg-6 d-flex mb-4 pt-lg-5 pt-0">
                                                    <input name="is_admin" onChange={() => setFieldValue("is_admin", !values.is_admin)}
                                                        className="form-check-input" type="checkbox" id="is_admin" checked={values.is_admin} />
                                                    <label className="form-label ms-3" htmlFor="pressReleaseDate">Is Admin</label>

                                                    {errors.is_admin && touched.is_admin ? (
                                                        <p className="form-error text-danger">
                                                            {errors.is_admin}
                                                        </p>
                                                    ) : error.is_admin ? (
                                                        <p className="form-error text-danger">
                                                            {error.is_admin}
                                                        </p>
                                                    ) : null}
                                                </div>


                                                <div className="bottom-actions mt-4 text-end">
                                                    <input onClick={handlenaviagte} type="submit" value="Cancel" className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2" />
                                                    <input onClick={handleSubmit} type="submit" value="Save" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 ms-2" />

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default CreateUserRole;
