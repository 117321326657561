   const mandatoryFieldsConfig = {
    configurations: [
      {
        websiteId: "TOC",
        moduleName: "offers",
        mandatoryFields: [],
        extraFields:[]
      },
      {
        websiteId: "AHR",
        moduleName: "offers",
        mandatoryFields: ["discountText"],
        extraFields:["InnerBannerImage",]
      },
      {
        websiteId: "TOC",
        moduleName: "pressrelease",
        mandatoryFields: [],
        extraFields:[]
      },
      {
        websiteId: "AHR",
        moduleName: "pressrelease",
        mandatoryFields: ["pressReleaseDate","pressReleaseTime"],
        extraFields:[]
      },
      {
        websiteId: "TOC",
        moduleName: "dinning",
        mandatoryFields: [],
        extraFields:["highlightsTitle","highlightsDescription","chefSignatue","leftImage","rightImage","restLogo","ambienceTitle"]
      },
      {
        websiteId: "AHR",
        moduleName: "dinning",
        mandatoryFields: ["tagDescription"],
        extraFields:["tagFeatures","innerBannerImage"]
      },
      {
        websiteId: "TOC",
        moduleName: "enquiry",
        mandatoryFields: [],
        extraFields:["subject"]
      },
      {
        websiteId: "AHR",
        moduleName: "enquiry",
        mandatoryFields: [],
        extraFields:[]
      }
      ,
      {
        websiteId: "TOC",
        moduleName: "property",
        mandatoryFields: [],
        extraFields:["viewlink","footNotes"]
      },
      {
        websiteId: "AHR",
        moduleName: "property",
        mandatoryFields: [],
        extraFields:["innerBannerImage"]
      }
    ]
  };
  
  // Function to get mandatory fields based on websiteId and moduleName
  export const getMandatoryFields = (websiteId, moduleName) => {
   // console.log("bwebsiteId",websiteId)
//console.log("moduleName",moduleName)
    const config = mandatoryFieldsConfig.configurations.find(
      (config) => config.websiteId === websiteId && config.moduleName === moduleName
    );
   // console.log("config",config)
    return config ? config.mandatoryFields : [];
  };
  export const getExtraFields = (websiteId, moduleName) => {
    const config = mandatoryFieldsConfig.configurations.find(
      (config) => config.websiteId === websiteId && config.moduleName === moduleName
    );
    return config ? config.extraFields : [];
  };