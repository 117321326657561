import React from "react";
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify';
import * as dataquery from "../dataService/DataService";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Button, Modal } from 'react-bootstrap';

const DeleteRestaurantTypeModal = ({ row, show, handleClose, rowData, rowNameData,onInvalidate }) => {
  const queryClient = useQueryClient();


  const handleArchive = (data) => {


    epcArchive.mutate(data);
    handleClose();
  };
  const epcArchive = useMutation(dataquery.deleteRestaurantTypes, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      // queryClient.invalidateQueries('getAllRestaurents')
    //   queryClient.resetQueries('getAllRestaurents')
    onInvalidate();
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete - {rowNameData}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Once you delete this data, you won't be able to recover it. Are
        you sure you want to go ahead?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-uppercase" onClick={handleClose} >
            Close
          </Button>
          <Button variant="primary" className="btn-danger text-uppercase" onClick={() => handleArchive(rowData)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default DeleteRestaurantTypeModal;
