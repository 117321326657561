import React from "react";
import { ogTypesItems } from "../managePages/ManagePages";
// ogTitle:"",
//     ogDescription:"",
//     ogType:"",
//     ogUrl:"",
//     // ogImage: ogImagePlaceholer,
//     ogSiteName:"",

const OGtags = ({
  handelChange,
  values,
  ogImage,
  showCropMediaModal,
  setSelectedImageValue,
  setOgimage,
}) => {
  const handleImageUpload = () => {
    setSelectedImageValue("OGIMAGE");
    showCropMediaModal(true);
  };
  const handelRemoveImage = () => {
    if (setOgimage) {
      setOgimage(null);
    } else {
      console.log("errro in removing the image ");
    }
  };



  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <label className="form-label" htmlFor="ogTitle">
            OG Title
          </label>
          <div className="mb-4">
            <input
              type="text"
              className="form-control py-3 shadow-none px-4"
              name="ogTitle"
              value={values?.ogTitle}
              onChange={handelChange}
              id="ogTitle"
              maxLength={200}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <label className="form-label" htmlFor="ogDescription">
            OG Description
          </label>
          <div className="mb-4">
            <input
              type="text"
              className="form-control py-3 shadow-none px-4"
              name="ogDescription"
              value={values?.ogDescription}
              onChange={handelChange}
              id="ogDescription"
              maxLength={200}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <label className="form-label" htmlFor="ogType">
            OG Type
          </label>
          <div className="mb-4">
            {/* <input
              type="text"
              className="form-control py-3 shadow-none px-4"
              name="ogType"
              value={values?.ogType}
              onChange={handelChange}
              id="ogType"
              maxLength={200}
            /> */}

            <select
              name="ogType"
              id="ogType"
              onChange={handelChange}
              value={values?.ogType}
              className="py-3 shadow-none px-4 d-block border w-100 text-capitalize  form-select"
            >
              {ogTypesItems?.map((item, index) => {
                return (
                  <option className="" key={index} value={item?.value}>
                    {item?.placeholder}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {/* <div className="col-lg-6">
          <label className="form-label" htmlFor="ogUrl">
            OG Url
          </label>
          <div className="mb-4">
            <input
              type="text"
              className="form-control py-3 shadow-none px-4"
              name="ogUrl"
              value={values?.ogUrl}
              onChange={handelChange}
              id="ogUrl"
              maxLength={200}
            />
          </div>
        </div> */}
      </div>

      {/* for og image  */}
      <div className="col-12">
        <div className="mb-4">
          <div className="d-flex align-items-center">
            <label className="form-label mb-0"> OG Image</label>
          </div>
          {!ogImage && (
            <label
              htmlFor="UploadCover"
              className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
              onClick={(e) => {
                handleImageUpload();
              }}
              // ref={imageRef}
              tabIndex={-1}
            >
              <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                >
                  Upload image from gallery
                </button>
              </div>
            </label>
          )}
          {ogImage && (
            <div
              className="uploaded-img-view"
              style={{
                height: "200px",
                width: "200px",
                position: "relative",
              }}
            >
              <div>
                <a
                  href={
                    typeof ogImage === "string"
                      ? ogImage
                      : URL.createObjectURL(ogImage)
                  }
                  target="_blank"
                >
                  <img
                    src={
                      typeof ogImage === "string"
                        ? ogImage
                        : URL.createObjectURL(ogImage)
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </a>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                  }}
                >
                  <button
                    className="remove-image-button"
                    onClick={(e) => {
                      handelRemoveImage();
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      
    </>
  );
};

export default OGtags;
