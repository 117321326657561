import React, { useState, useEffect } from "react";
import albumPng from "../../assets/images/album.png";
import { Button } from "react-bootstrap";
import  { components } from "react-select";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";

import * as query from "../../services/service";
import Pagination from "../../components/Pagination";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as querydata from "../../services/service";
import { toast } from "react-toastify";
import backarrow from "../../assets/images/back-arrow.svg";
import { deleteAlbumData } from "../dataService/DataService";
import DeleteAlbumModalComponent from "../../components/galleryCommonComponent/DeleteAlbumModalComponent";
import AlbumMadalComponent from "../../components/galleryCommonComponent/AlbumMadalComponent";
import MediaFileButton from "../../components/galleryCommonComponent/MediaFileButton";

export const albumSchema = Yup.object({
  albumTitle: Yup.string()
    .typeError("Album Title must be a string")
    .trim()
    .required("Please enter Album Title"),
});

// 
function ManageAlbum() {
  const queryClient = useQueryClient();
  const { websiteId } = useParams();
  const getMediaAlbumFolData = useQuery(
    ["getMediaAlbumFolData", +websiteId],
    () => query.getMediaAlbumByWebId(websiteId)
  );

 
  const navigate = useNavigate();
  //const { websiteId } = useParams();
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [selectedMediaAlbumId, setSelectedMediaAlbumId] = useState("");


  // const getMediaAlbum = useQuery(
  //   ["getMediaAlbum", +selectedMediaAlbumId],
  //   () => query.getMediaAlbumNameById(selectedMediaAlbumId)
  // );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const options = [
    { value: "all", label: "Select All" },
    { value: "3", label: "Photos" },
    { value: "4", label: "Videos" },
  ];

  const handleSelectChange = (selected) => {
    // If "Select All" is selected, set all options as selected.
    // Otherwise, update the selected options.
    setSelectedOptions(
      selected.some((item) => item.value === "all")
        ? options.slice(1)
        : selected
    );
  };

  // Handler function for filter value
  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };
  const [itemsPerPage, onItemsPerPageChange] = useState(20);
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    onItemsPerPageChange(newItemsPerPage);
  };
  // Start line For pagination
  // const itemsPerPage = 10; // Number of items to display per page
  const totalPages = Math.ceil(
    getMediaAlbumFolData?.data?.data.length / itemsPerPage
  );
  const handlePageChange = (newPage) => {
    // Validate newPage to ensure it's within the valid range of pages
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  // Ensure that data is an array before using slice
  const dataArray = Array.isArray(getMediaAlbumFolData?.data?.data)
    ? getMediaAlbumFolData?.data?.data
    : [];
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const albumtData = dataArray.slice(startIndex, endIndex);
  // End line For pagination
  const totalItems = dataArray.length;

  //For update open modal
  const [albumName, SetAlbumName] = useState("")
  const handleShow = (e,mediaAlbum) => {
    e.preventDefault();
    SetAlbumName(mediaAlbum?.media_album)
    setSelectedMediaAlbumId((prevvalues) => {
      return mediaAlbum?.media_album_id;
    });
    setShow(true);
  };

  // const [getMediaAlbum, setGetMediaAlbum] = useState(null);

  // useEffect(() => {
  //   if (selectedMediaAlbumId !== null) {
  //     const fetchData = async () => {
  //       try {
  //         const result = await querydata.getMediaAlbumNameById(
  //           selectedMediaAlbumId
  //         );
  //         // setGetMediaAlbum(result);
  //         // Process the result or update state as needed
  //       } catch (error) {
  //         // Handle errors
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [selectedMediaAlbumId]);

 
  
  let initialValues = {
    // albumTitle:getMediaAlbum?.data?.data?.length>0?getMediaAlbum?.data?.data[0]?.media_album:""
    albumTitle:albumName||"" // add this on 04-11-2024
    
  };


  // useEffect(() => {
  //   setValues({ ...initialValues });
  //   // setFieldValue('albumTitle',getMediaAlbum&&getMediaAlbum.data[0].media_album)
  // }, [getMediaAlbum.data]);
  useEffect(() => {
    setValues({ ...initialValues });
    // setFieldValue('albumTitle',getMediaAlbum&&getMediaAlbum.data[0].media_album)
  }, [albumName]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: initialValues,
    validationSchema: albumSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();

      formData.append("albumTitle", values.albumTitle);
 
      if (selectedMediaAlbumId > 0) {
        updateprojectMutation.mutate({
          formData: values.albumTitle,
          mediaAlbumId: selectedMediaAlbumId,
        });
      } else {
        if (values.albumTitle !== "" || websiteId > 0) {
          addnewprojectMutation.mutate({
            formData: values.albumTitle,
            websiteId: websiteId,
          });
        } else {
          toast.dismiss();
          toast.error("Please contact to administrator");
        }
      }

      //  action.resetForm();
    },
  });

  const addnewprojectMutation = useMutation(querydata.insertMediaAlbumName, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      handleClose();
      queryClient.invalidateQueries("getMediaAlbumFolData");
      resetForm();
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });
  const updateprojectMutation = useMutation(querydata.updateMediaAlbumName, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      handleClose();
      queryClient.invalidateQueries("getMediaAlbumFolData");
      resetForm();

    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });
  let albumHeading = "Add Album";
  if (selectedMediaAlbumId > 0) {
    albumHeading = "Edit Album";
  }
  const [showDeleteAlbumModal, setShowDeleteAlbumModal] = useState(false);
  const [DeleteAlbumName, setDeleteAlbumName] = useState(false);
  const [DeleteAlbumId, setDeleteAlbumId] = useState(false);
  const handleShowDeleteAlbumModal = (albumData) => {
    setDeleteAlbumId(albumData.media_album_id);
    setDeleteAlbumName(albumData.media_album);
    setShowDeleteAlbumModal(true);
  };
  const handleCloseDeleteAlbumModal = () => {
    setShowDeleteAlbumModal(false);
  };




  // const handleDeleteAlbum = (albumId) => {
  //   deleteUserRecord.mutate(albumId);
  //   handleCloseDeleteAlbumModal();
  // };
  // const deleteUserRecord = useMutation(deleteAlbumData, {
  //   onError: (error) => {
  //     toast.dismiss();
  //     toast.error(error.message);
  //   },
  //   onSuccess: (data) => {
  //     toast.dismiss();
  //     toast.success(data.message);
  //     queryClient.invalidateQueries('getMediaAlbumFolData')
  //   },
  // });
 
  const getCookie = (name) => {
    const cookies = document.cookie.split(';');  
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
};

const myCookieUserId = getCookie('userId');
const websiteIds = websiteId;
const webNavData = useQuery(['getNavItemByWebId', websiteIds,myCookieUserId], () =>
  querydata.getNavItemByWebId(websiteIds,myCookieUserId)
);

const isGalleryActive = webNavData?.data?.data?.some(
    (item) =>  item.userrolenavisactive > 0
);


  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to={`/manage-folder/`}>
                    Manage Gallery
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Manage Albums
                </li>

              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to="/manage-folder"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">
                Manage <strong>Albums</strong>
              </h4>
            </div>
            <div className="header-action">
            

              {/* <Button
                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                onClick={(e)=>handleShow(e,'')}
              >
                Add Album
              </Button> */}

               {/* Add this on 04-11-2024 down ↓ */}
            <MediaFileButton webId={websiteId} buttonText={'ADD ALBUM'} handleShow={handleShow} />
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <div className="left-header">

                  </div>
                  <div className="right-action d-flex">
                    <div className="atmosphere-hr ms-2 me-2">
                      <div className="position-relative multiselect-box ">
                        <div className="ms-options-wrap">

                        </div>
                      </div>
                    </div>
                    <div className="atmosphere-hr filter-album">

                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="gallery-assets">
                    <ul className="list-unstyled m-0 p-0 d-flex flex-wrap align-items-center">

                      {albumtData &&
                        albumtData.map((album) => (
                          <li key={album.media_album_id}>

                            <div className="album-view border rounded-3 position-relative overflow-hidden">
                              <span className="position-absolute start-0 top-0 z-2 mt-2 ms-2 bg-success badge">{album.file_count}</span>
                         
                              <div className="album-set-icon">
                              {isGalleryActive && ( <div className="edit-album-cover lh-1 position-absolute end-0 top-0 z-2" >

                                  <span
                                    className="btn pt-0 px-1 pb-0 me-2 btn-sm btn-secondary"
                                    // onClick={(e) =>
                                    //   handleShow(e,album.media_album_id)
                                    // } old function
                                    onClick={(e) =>
                                      handleShow(e,album)
                                    }
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span
                                    className="btn pt-0 px-1 pb-0 me-2 btn-primary bg-danger border-danger btn-sm btn-theme"
                                    onClick={() =>
                                      handleShowDeleteAlbumModal(album)
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>)}
                                <Link
                                  to={`/manage-folder/manage-media/album-view/${websiteId}/${album.media_album_id}`}
                                  className="position-relative text-decoration-none text-white d-flex px-2 py-3"
                                >
                                  <img
                                    src={albumPng}
                                    alt="Album"
                                    title="Album"
                                  />
                                  <div className="album-title position-absolute start-0 w-100 z-1 bottom-0 py-3 px-3 text-center">
                                    <h6 className="mb-0">
                                      {album.media_album || "No Folder"}
                                    </h6>
                                    <div className="created-info mt-3 d-flex justify-content-between align-items-center">
                                      <span>By-: {album.user_name}</span>
                                      <span>
                                        On-:
                                        {moment(album.created_on).format(
                                          "MMMM D, YYYY HH:mm:ss"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <div className="d-flex align-items-center flex-wrap justify-content-between mt-4 mb-3 px-md-0 px-3">
                    <div className="show-enteries">
                      <div className="page-item">
                            <div className="ps-3">
                              <label
                                className="d-flex align-items-center"
                                style={{ maxWidth: "190px" }}
                              >
                                Show{" "}
                                <select
                                  className="form-select ms-3 me-2"
                                  value={itemsPerPage}
                                  onChange={handleItemsPerPageChange}
                                >
                                  <option value={20}>20</option>
                                  <option value={40}>40</option>
                                  <option value={60}>60</option>
                                  <option value={80}>80</option>
                                  <option value={100}>100</option>
                                </select>{" "}
                                entries
                              </label>
                            </div>
                          </div>
                      </div>
                      <div className="atmosphere-pagination album-pagination mt-0 d-flex">
                        <nav aria-label="...">
                          <ul className="pagination m-0 d-flex justify-content-end">
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={handlePageChange}
                              visiblePages={itemsPerPage}
                              totalItems={totalItems}
                            />
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">{albumHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3">
            <div className="upload-file-wrapper">
              <form>
                <div className="row">
                  {
                    <div className="col-12 mb-4">
                      <label className="form-label" htmlFor="Name">
                        Album Title
                      </label>
                      <div className="mb-4">
                        <input
                          type="text"
                          name="albumTitle"
                          value={values.albumTitle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control py-3 shadow-none px-4"
                          maxLength={100}
                        />

                        {errors.albumTitle && touched.albumTitle ? (
                          <p className="form-error text-danger">
                            {errors.albumTitle}
                          </p>
                        ) : error.albumTitle ? (
                          <p className="form-error text-danger">
                            {error.albumTitle}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  }
                </div>
                <div className="col-12 pt-5 text-end mb-2">
                  <Button
                    onClick={handleClose}
                    data-bs-dismiss="modal"
                    value="Cancel"
                    className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-2 me-1"
                  >
                    Cancel
                  </Button>
                  <input
                    onClick={handleSubmit}
                    type="submit"
                    value="Submit"
                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteAlbumModal} onHide={handleCloseDeleteAlbumModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete - {DeleteAlbumName}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Once you delete this Album, all the files in this album will also be deleted irrevocably. Are
            you sure you want to go ahead?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-uppercase" onClick={handleCloseDeleteAlbumModal} >
            Cancel
          </Button>
          <Button variant="primary" className="btn-danger text-uppercase" onClick={() => handleDeleteAlbum(DeleteAlbumId)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}

      <AlbumMadalComponent
                albumHeading={albumHeading}
                show={show}
                handleClose={handleClose}
                //   selectedMediaAlbumId={selectedMediaAlbumId}
                initialValues={initialValues}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
            />
            <DeleteAlbumModalComponent
                show={showDeleteAlbumModal}
                handleClose={handleCloseDeleteAlbumModal}
                rowNameData={DeleteAlbumName}
                rowData={DeleteAlbumId}
            />
    </>
  );
}

export default ManageAlbum;