import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import dragDropImg from "../../../../assets/images/drag.svg";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Item from "./Item";

function AmenityDragDrop({
  handleDragComponentCheckboxChange,
  selectedValues = [],
  setSelectedValues,
  item = [],
  setSortedAmenity,
  setAmenitesItems,
  index = 0,
}) {
  const ToolLink = ({ ids, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={ids}>{title}</Tooltip>}>
      <a className="text-dark text-decoration-none">{children}</a>
    </OverlayTrigger>
  );
  const handleCheckboxChang = (amenityId) => {
    setSortedAmenity((prev) => {
      // Check if `prev` is null or undefined
      if (!prev) {
        return []; // Return an empty array if `prev` is null or undefined
      }
      // Filter out the item with the given amenity_id
      return prev.filter((item) => item.amenity_id !== amenityId);
    });
  
    setSelectedValues((prevValues) => {
      // Check if the amenityId is already in the array
      if (prevValues.includes(amenityId)) {
        // Remove the amenityId if it's present
        return prevValues.filter((id) => id !== amenityId);
      } else {
        // Add the amenityId if it's not present
        return [...prevValues, amenityId];
      }
    });
  
    // Handle the case when `setAmenitesItems` is undefined
    if (!setAmenitesItems) return;
  
    setAmenitesItems((prev) => {
      // Check if `prev` is null or undefined
      if (!prev) {
        return []; // Return an empty array if `prev` is null or undefined
      }
      // Filter out the item with the given amenity_id
      return prev.filter((item) => item.amenity_id !== amenityId);
    });
  };
  
  // const handleCheckboxChang = (amenityId) => {
  //   setSortedAmenity((prev) => {
  //     // Filter out the item with the given amenity_id
  //     return prev.filter((item) => item.amenity_id !== amenityId);
  //   });

  //   setSelectedValues((prevValues) => {
  //     // Check if the amenityId is already in the array
  //     if (prevValues.includes(amenityId)) {
  //       // Remove the amenityId if it's present
  //       return prevValues.filter((id) => id !== amenityId);
  //     } else {
  //       // Add the amenityId if it's not present
  //       return [...prevValues, amenityId];
  //     }
  //   });
  //   // this is only for add case
  //   if (!setAmenitesItems) return;
  //   setAmenitesItems((prev) => {
  //     return prev.filter((item) => item.amenity_id !== amenityId);
  //   });
  // };
  const { attributes, listeners, setNodeRef, transition, transform ,isDragging} =
    useSortable({ id: item?.amenity_id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <div
        key={item.amenity_id}
        className="col-xl-4 col-lg-6"
        ref={setNodeRef}
        style={style}      
        
      >
        <div className="amenity-wrap d-flex align-items-center mb-3 border px-3 py-3 h-100">
          <div className=""  style={{ cursor: "grab" }} {...attributes}  {...listeners}>
            <img src={dragDropImg} alt="" title="" />
          </div>       
          <div className="ms-4 check-amenity">          
            <input
              type="checkbox"
              value={item.amenity_id}
              onMouseUp={() => handleCheckboxChang(item.amenity_id)}
              checked={selectedValues?.includes(item.amenity_id)}
              //id={item.amenity_id}
              name={`amenity${index}`}
            />
          </div>
     
          <div className="amenity-view d-flex px-2">
            <div className="icon-amenity">
              <img src={item.icon_path} />
            </div>
            <div className="name-amenity px-2">
              <ToolLink
                title={item.amentity_name}
                ids={`tooltip-${item.amenity_id}`}
              >
                {item.amentity_name}
              </ToolLink>
            </div>
          </div>        
 
        </div>
      </div>
    </>
  );
}

export default AmenityDragDrop;
