import { useNavigate, Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../dataService/DataService";
import backarrow from "../../assets/images/back-arrow.svg";
import { getCurrentDate } from "../../helper/CommonHelper";
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelection from "../manageAlbum/AlbumViewSelection";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsFileBreakFill, BsFillInfoCircleFill } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import { getMandatoryFields, getExtraFields } from "../../mandatoryfileds";
import { useContext } from "react";
import { GlobalContext } from "../../globalContext";
import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModal";
import pdfImg from "../../assets/images/pdf-doc.svg";
// import OGtags from "../manageProperties/OGtags"
import OGtags from "./OGtags";
import { get } from "jquery";

// let bwebsiteId="";
// if (window.location.origin=="http://localhost:3000")
//     bwebsiteId = "TOC";
// else if (window.location.origin=="http://localhost:3001")
//     bwebsiteId = "AHR";

const CreateOffers = () => {
  const { bwebsiteId } = useContext(GlobalContext);
  const moduleName = "offers";

  // copy existing offers
  const [allOffersData, setAllOffersData] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState(null);

  let allOffers = useQuery("getAllOffers", querydata.getAllOffers);

  useEffect(() => {
    if (allOffers?.data) {
      setAllOffersData(allOffers?.data?.data);
    }
  }, [allOffers]);

  // Handle offer selection
  const handleOfferChange = (e) => {
    const offerId = e.target.value;
    setSelectedOfferId(offerId);

    // Reset image and PDF states
    setImage(null);
    setmainCroppedImage(null);
    setmainCroppedFile(null);
    setInnerBannerImage(null);
    setInnerBannerFile(null);
    setBroucherFile(null);
    setOgImage(null);
    setFlag(true);
  };

  // Fetch single offer data

  const { data: singleOfferData, refetch } = useQuery(
    ["Resorts", selectedOfferId],
    () => querydata.getSingleOffer(selectedOfferId),
    {
      enabled: false, // Prevent auto-fetch on mount
    }
  );

  // Trigger refetch when selectedOfferId changes
  useEffect(() => {
    if (selectedOfferId) {
      refetch();
    } else {
      setValues(initialValues);
    }
  }, [selectedOfferId, refetch]);

  // Extract filename from the URL
  const getFileNameFromUrl = (url) => {
    return url.substring(url.lastIndexOf("/") + 1) || "File";
  };

  // Function to convert URL to File
  async function urlToFile(url, fileName = "offerImage") {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      if (!response.ok) {
        throw new Error("Error in generating image ");
      }

      // Extract file extension from the URL or use a default based on the blob type
      const fileExtension =
        blob.type === "application/pdf"
          ? "pdf"
          : blob.type.startsWith("image/")
          ? "png"
          : "bin";

      // Construct the file name with the appropriate extension
      const dynamicFileName = fileName.includes(".")
        ? fileName
        : `${fileName}.${fileExtension}`;

      // Extract dynamic filename if not provided
      // const dynamicFileName = fileName;

      return new File([blob], dynamicFileName, { type: blob.type });
    } catch (error) {
      console.log("inside error ", error, "  and file name is : ", fileName);
      return {
        flag: false,
        fileName,
      };
    }
  }

  const mandatoryFields = getMandatoryFields(bwebsiteId, moduleName);
  const extraFields = getExtraFields(bwebsiteId, moduleName);

  const offerSchema = Yup.object({
    offerName: Yup.string().trim().required("Please enter offer name"),
    resortId: Yup.number().required("Please select resort"),
    //discountText: Yup.string().trim(),//condition
    // discountText: Yup.string().trim().when("discountText", {
    //     is: () => mandatoryFields.includes('discountText'),
    //     then: Yup.string().required("Please enter discount text"),
    //     otherwise: Yup.string().trim()
    //   }),
    // discountText: Yup.string().trim().when([], {
    //     is: () => mandatoryFields.includes('discountText'),
    //     then: Yup.string().required("Please enter discount text"),
    //     otherwise: Yup.string().trim()
    // }),
    discountText: Yup.string()
      .trim()
      .test(
        "is-discount-text-required",
        "Please enter discount text",
        function (value) {
          return !mandatoryFields.includes("discountText") || !!value; // If discountText is required, ensure it's not empty
        }
      ),
    description: Yup.string().trim().required("Please enter description"),
    website: Yup.string().trim().required("Please enter URL"),
    termsconditions: Yup.string()
      .trim()
      .required("Please enter  terms & conditions"),
    highlights: Yup.string().trim().required("Please enter highlights"),
    startDate: Yup.string().required("Please select start date"),
    endDate: Yup.string().required("Please select end date"),
    slugName: Yup.string()
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        "Only alphanumeric characters, hyphens, and underscores are allowed"
      )
      .required("Please enter slug"),
    metaTitle: Yup.string().trim(),
    keyWords: Yup.string().trim(),
    metaDescription: Yup.string().trim(),
  });
  const ToolLink = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="text-dark">{children}</a>
    </OverlayTrigger>
  );
  const navigate = useNavigate();
  const [offerfile, setofferfile] = useState("");
  const [image, setImage] = useState(null);
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [ogImagePlaceholer, setOgImage] = useState(null);

  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const [innerBannerImage, setInnerBannerImage] = useState(null);
  const [innerBannerFile, setInnerBannerFile] = useState(null);
  const [settingValue, setSettingValue] = useState("");
  const [offerValForBanner, setOfferValBanner] = useState("");
  const [broucherValue, setBroucher] = useState("");
  const [broucherFile, setBroucherFile] = useState(null);
  const [bannerErrorMsg, setBannerErrorMsg] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [actions, setAction] = useState();
  const closeCropMediaModal = () => setCropMediaModal(false);
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // Text formatting
      ["link"], // Media
    ],
  };
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "offer_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("offer_aspect_ratio")
  );

  const getOgAspectRatio = useQuery(
    ["AspectRatio", "og_image_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("og_image_aspect_ratio")
  );
  const offerBannerImageRatio = useQuery(
    ["AspectRatio", "offer_inner_banner_image"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("offer_inner_banner_image")
  );

  const kolkatofferTypes = useQuery(
    "offerTypes",
    querydata.getKolKataOfferType
  );

  const handleRemoveImage = (propVal) => {
    // Handle removing the image
    if (propVal === "OFFERIMAGE") {
      setImage(null);
      setmainCroppedImage(null);
      setmainCroppedFile(null);
    }
    if (propVal === "BROUCHER") {
      setBroucherFile(null);
    }
    if (propVal === "INNERBANNER" && extraFields.includes("InnerBannerImage")) {
      //condition
      setBannerErrorMsg(true);
      setInnerBannerFile(null);
      setInnerBannerImage(null);
    }
  };

  useEffect(() => {
    //condition
    if (innerBannerFile && extraFields.includes("InnerBannerImage")) {
      setBannerErrorMsg(false);
    }
  }, [innerBannerFile]);

  const showCropMediaModal = (propVal) => {
    if (propVal === "INNERBANNER" && extraFields.includes("InnerBannerImage")) {
      //condition
      setOfferValBanner(propVal);
      setSettingValue(
        offerBannerImageRatio?.data?.data?.setting_value
          ? offerBannerImageRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (propVal === "BROUCHER") {
      setBroucher(propVal);
      // setSettingValue(
      //     offerBannerImageRatio?.data?.data?.setting_value
      //         ? offerBannerImageRatio?.data?.data?.setting_value
      //         : 1 / 1
      // );
    }
    if (propVal === "OFFERIMAGE") {
      setOfferValBanner(propVal);
      setSettingValue(
        getAspectRatio?.data?.data?.setting_value
          ? getAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (propVal === "OGIMAGE") {
      setOfferValBanner(propVal);
      setSettingValue(
        getOgAspectRatio?.data?.data?.setting_value
          ? getOgAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }

    setCropMediaModal(true);
  };

  // crop code end

  const [initialValues, setInitialValues] = useState({
    offerName: "",
    slugName: "",
    discount: "",
    resortId: "",

    metaTitle: "",
    keyWords: "",
    metaDescription: "",
    startDate: "",
    endDate: "",
    featuredHR: false,
    featuredKF: false,

    description: "",
    website: "",
    termsconditions: "",
    highlights: "",
    statusId: "1",
    discountText: "",

    offerImgTag: "",
    innerBannerImgTag: "",
    offerTypeId: "0",

    // og tags

    ogTitle: "",
    ogDescription: "",
    ogType: "",
    ogUrl: "",
    // ogImage: ogImagePlaceholer,
    ogSiteName: "",
  });

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  const websiteName = getCookie("websiteName")
    ? getCookie("websiteName")
    : "OMK";
  const websiteCode = getCookie("websiteCode")
    ? getCookie("websiteCode")
    : "SADARMANZIL";
  const offerNameRef = useRef(null);
  const discountTextRef = useRef(null);
  const resortIdRef = useRef(null);
  const descriptionRef = useRef(null);
  const websiteRef = useRef(null);
  const termsconditionsRef = useRef(null);
  const highlightsRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const slugRef = useRef(null);
  const maincroppedFileRef = useRef(null);
  const innerBannerRef = useRef(null);
  const handleOfferNameChange = (event) => {
    const value = event.target.value;

    setFieldValue("offerName", value);
    setFieldValue("slugName", value.toLowerCase().replace(/\s+/g, "-"));
  };
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };

  const handlecheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFieldValue("featuredHR", checked);
  };
  const handlecheckboxChangeR = (event) => {
    const { name, checked } = event.target;
    setFieldValue("featuredKF", checked);
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: offerSchema,
    onSubmit: async (values, action) => {
      let imageFlag = true;
      let imageErrorMsg = "";
      const formData = new FormData();

      formData.append("offerName", values.offerName);
      formData.append("slugName", values.slugName);
      formData.append("metaTitle", values.metaTitle);
      formData.append("keyWords", values.keyWords);
      formData.append("metaDescription", values.metaDescription);
      formData.append("resortId", values.resortId);
      formData.append("discountText", values.discountText);
      formData.append("discountPercent", values.discount);

      // og tags

      // formData.append("ogImage", ogImagePlaceholer);

      if (typeof ogImagePlaceholer === "string") {
        const ogImageFile = await urlToFile(ogImagePlaceholer, "ogImage");
        if (ogImageFile.hasOwnProperty("flag") && !ogImageFile.flag) {
          // toast.dismiss();
          // toast.error(`error in generating  ${ogImageFile.fileName}`);
          imageErrorMsg = `Error in generating ${ogImageFile.fileName}`;
          imageFlag = false;
        }
        formData.append("ogImage", ogImageFile);
      } else {
        formData.append("ogImage", ogImagePlaceholer);
      }

      formData.append("ogTitle", values.ogTitle);
      formData.append("ogDescription", values.ogDescription);
      formData.append("ogType", values.ogType);
      formData.append("ogUrl", values.ogUrl);
      formData.append("ogSiteName", values.ogSiteName);

      // if (broucherFile != null) {
      //   formData.append("brochureFile", broucherFile);
      // }

      if (broucherFile) {
        if (typeof broucherFile === "string") {
          console.log("broucherFile  : ", broucherFile);
          const broucherFileObj = await urlToFile(broucherFile, "brochureFile");
          console.log(broucherFileObj, "broucherFileObj");

          if (broucherFileObj.hasOwnProperty("flag") && !broucherFileObj.flag) {
            // toast.dismiss();
            // toast.error(`error in generating  ${broucherFileObj.fileName}`);
            imageErrorMsg = `Error in generating ${broucherFileObj.fileName}`;
            imageFlag = false;
          }
          formData.append("brochureFile", broucherFileObj);
        } else {
          formData.append("brochureFile", broucherFile);
        }
      }

      if (values?.offerTypeId !== "0") {
        formData.append("offerTypeId", values.offerTypeId);
      } else {
        formData.append("offerTypeId", null);
      }
      // formData.append("description", values.description);
      const regex = /\s{2,}/g;

      // Replace matched spaces with &nbsp;
      const result = offerDesc.replace(
        regex,
        (match) => " " + "&nbsp;".repeat(match.length - 1)
      );
      if (result) {
        formData.append("description", result);
      }

      formData.append("websiteURL", values.website);
      formData.append("termsCondition", values.termsconditions);
      formData.append("offerStartDate", values.startDate);
      formData.append("offerEndDate", values.endDate);
      formData.append("isFeaturedResort", values.featuredKF);
      formData.append("isFeaturedBrand", values.featuredHR);
      formData.append(
        "websiteId",
        getCookie("websiteId") ? getCookie("websiteId") : 0
      );

      formData.append("highlights", values.highlights);
      formData.append("is_active", 0);
      if (extraFields.includes("InnerBannerImage"))
        formData.append("innerBannerImgTag", values.innerBannerImgTag); //condition
      formData.append("offerImgTag", values.offerImgTag);

      if (innerBannerFile && extraFields.includes("InnerBannerImage")) {
        if (typeof innerBannerFile === "string") {
          const innerBannerFileObj = await urlToFile(
            innerBannerFile,
            "inner Banner Image "
          );

          if (
            innerBannerFileObj.hasOwnProperty("flag") &&
            !innerBannerFileObj.flag
          ) {
            // toast.dismiss();
            // toast.error(`error in generating  ${innerBannerFileObj.fileName}`);
            imageErrorMsg = `Error in generating ${innerBannerFileObj.fileName}`;

            imageFlag = false;
          }
          // console.log("innerBannerFileObj    : ")
          formData.append("innerBannerImage", innerBannerFileObj);
        } else {
          formData.append("innerBannerImage", innerBannerFile);
        }
      } else {
        setBannerErrorMsg(true);
      }

      // let flag = false;
      // if (maincroppedFile) {
      //   formData.append("offerImage", maincroppedFile);
      //   flag = true;
      // } else {
      //   toast.dismiss();
      //   toast.error("Please upload Image");
      //   // error.file = "Please Upload Image";
      //   if (maincroppedFileRef.current) {
      //     maincroppedFileRef.current.focus();
      //   }
      //   return;
      // }

      let flag = false;
      if (image) {
        const maincroppedFileObj = await urlToFile(image, "offer image ");

        if (
          maincroppedFileObj.hasOwnProperty("flag") &&
          !maincroppedFileObj.flag
        ) {
          // toast.dismiss();
          // toast.error(`error in generating  ${maincroppedFileObj.fileName}`);
          imageErrorMsg = `Error in generating ${maincroppedFileObj.fileName}`;
          imageFlag = false;
        }

        formData.append("offerImage", maincroppedFileObj);

        flag = true;
      } else if (maincroppedFile) {
        formData.append("offerImage", maincroppedFile);
        flag = true;
      } else {
        if (image === null && maincroppedFile === null) {
          toast.dismiss();
          toast.error("Please upload offer image ");
          maincroppedFileRef.current.focus();
          return;
        }
      }
      console.log(imageFlag, "imageFlag");

      if (!imageFlag) {
        toast.dismiss();
        toast.error(`${imageErrorMsg}`);
        return;
      }

      if (extraFields.includes("InnerBannerImage")) {
        if (bannerErrorMsg) {
          //condition
          toast.dismiss();
          toast.error("Please upload Inner Banner Image");
          // error.file = "Please Upload Image";
          if (innerBannerRef.current) {
            innerBannerRef.current.focus();
          }
          return;
        } else if (!bannerErrorMsg) {
          //condition
          console.log("value of the falg is : ", flag);
          if (flag) {
            if (actions === "PREVIEW") {
              preview.mutate(formData);
            }
            if (actions === "SAVE") {
              addnewprojectMutation.mutate(formData);
            }
          } else {
            toast.dismiss();
            toast.error("please upload image");
            return;
          }
        }
      } else {
        console.log("value of the falg is : ", flag);
        if (flag) {
          if (actions === "PREVIEW") {
            preview.mutate(formData);
          }
          if (actions === "SAVE") {
            addnewprojectMutation.mutate(formData);
          }
        } else {
          toast.dismiss();
          toast.error("please upload image");
          return;
        }
      }
    },

    // validateOnBlur: true,
    // validateOnChange: true,
  });

  //updating the offer values on selection of offer
  useEffect(() => {
    if (singleOfferData?.data) {
      const offer = singleOfferData.data;

      setValues({
        offerName: offer.offer_name || "",
        slugName: offer.slug || "",
        discount: offer.discount_percent || "",
        resortId: offer.brand_resort_id || "",
        metaTitle: offer.meta_title || "",
        keyWords: offer.keywords || "",
        metaDescription: offer.meta_description || "",
        startDate: offer.offer_start_date
          ? offer.offer_start_date?.split("T")[0]
          : "",
        endDate: offer.offer_end_date
          ? offer.offer_end_date?.split("T")[0]
          : "",
        featuredHR: offer.is_featured_to_brand
          ? offer.is_featured_to_brand
            ? true
            : false
          : "",
        featuredKF: offer.is_featured_to_resort
          ? offer.is_featured_to_resort === 1
            ? true
            : false
          : "",
        website: offer.website_url || "",
        termsconditions: offer.terms_conditions || "",
        highlights: offer.highlights || "",
        statusId: offer.status_id || "1",
        discountText: offer.discount_text || "",
        offerImgTag: offer.offer_image_alt_tag || "",
        offerImage: offer.offer_image || "",
        innerBannerImgTag: extraFields.includes("InnerBannerImage")
          ? offer.inner_banner_alt_tag
          : "",
        offerTypeId: offer.offer_type_id || "0",
        ogTitle: offer.og_title || "",
        ogDescription: offer.og_description || "",
        ogType: offer.og_type || "",
        ogUrl: offer.og_url || "",
        ogSiteName: offer.og_site_name || "",
        description: offer.description || "",
      });
      setFlag(true);
    }
  }, [singleOfferData?.data]);

  useEffect(() => {
    const originalUrl =
      singleOfferData &&
      singleOfferData.data &&
      singleOfferData.data.offer_image;
    const bannerOriginalUrl =
      singleOfferData &&
      singleOfferData.data &&
      singleOfferData.data.inner_banner_image;

    const bannerModifiedUrl =
      bannerOriginalUrl &&
      bannerOriginalUrl.replace(
        "http://localhost:8000",
        "http://172.16.1.123:8000"
      );
    if (extraFields.includes("InnerBannerImage")) {
      setInnerBannerFile(bannerModifiedUrl);
      setInnerBannerImage(bannerModifiedUrl);
    }
    const brochureLink =
      singleOfferData &&
      singleOfferData.data &&
      singleOfferData.data.brochure_link;
    setBroucherFile(brochureLink);
    const modifiedUrl =
      originalUrl &&
      originalUrl.replace("http://localhost:8000", "http://172.16.1.123:8000");

    setImage(modifiedUrl);
    const imageSrc = singleOfferData?.data?.og_image ?? null;
    setOgImage(imageSrc);
  }, [singleOfferData?.data]);

  const [offerDesc, setDesc] = useState(values?.description);
  const [flag, setFlag] = useState(true);
  function stripHtml(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  const handelDescChange = (e) => {
    setFlag(false);
    setDesc(e);
    const plaintext = stripHtml(e);

    setValues((prevValues) => ({
      ...prevValues,
      description: plaintext,
    }));
  };

  useEffect(() => {
    if (flag) {
      setDesc(values?.description);
    }
  }, [values?.description]);

  // useEffect(() => {
  //     if (Object.values(errors).some(error => error !== undefined)) {
  //         const firstEmptyField = Object.entries(errors).find(
  //             ([, error]) => error !== undefined && !touched[error.path]
  //         );

  //         if (firstEmptyField) {
  //             const [name] = firstEmptyField;
  //             switch (name) {
  //                 case "offerName":
  //                     offerNameRef.current.focus();
  //                     break;
  //                 case "resortId":
  //                     resortIdRef.current.focus();
  //                     break;
  //                 case "discountText":
  //                     discountTextRef.current.focus();
  //                     break;

  //                 case "description":
  //                     descriptionRef.current.focus();
  //                     break;
  //                 case "website":
  //                     websiteRef.current.focus();
  //                     break;
  //                 case "termsconditions":
  //                     termsconditionsRef.current.focus();
  //                     break;

  //                 case "highlights":
  //                     highlightsRef.current.focus();
  //                     break;
  //                 case "startDate":
  //                     startDateRef.current.focus();
  //                     break;
  //                 case "endDate":
  //                     endDateRef.current.focus();
  //                     break;
  //                 case "slugName":
  //                     slugRef.current.focus();
  //                     break;
  //                 default:
  //                     break;
  //             }
  //         }
  //     }
  // }, [errors, touched]);

  const handelFocus = () => {
    if (Object.values(errors).some((error) => error !== undefined)) {
      const firstEmptyField = Object.entries(errors).find(
        ([, error]) => error !== undefined && !touched[error.path]
      );

      if (firstEmptyField) {
        const [name] = firstEmptyField;
        switch (name) {
          case "offerName":
            offerNameRef.current.focus();

            break;
          case "resortId":
            resortIdRef.current.focus();

            break;
          case "discountText":
            discountTextRef.current.focus();

            break;

          case "description":
            descriptionRef.current.focus();

            break;
          case "website":
            websiteRef.current.focus();

            break;
          case "termsconditions":
            termsconditionsRef.current.focus();

            break;

          case "highlights":
            highlightsRef.current.focus();

            break;
          case "startDate":
            startDateRef.current.focus();

            break;
          case "endDate":
            endDateRef.current.focus();

            break;
          case "slugName":
            slugRef.current.focus();

            break;
          default:
            break;
        }
      }
    }
    handleSubmit();
  };

  const preview = useMutation(querydata.previewOffers, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      const websiteUrl = `${data.previewURL}?isofferpreview=true&uid=${data.encryptedUserId}`;
      window.open(websiteUrl, "_blank");
    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });

  //form post
  const addnewprojectMutation = useMutation(querydata.insertOffer, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-offers");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const getAllResorts = useQuery("Resorts", querydata.getAllResorts);

  const handleofferfilechange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      const fileSize = selectedFile.size;
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result); // Set the image preview URL
      };

      if (!allowedFormats.includes(fileExtension)) {
        setofferfile(null);
        error.file =
          "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
      } else if (fileSize > maxFileSizeInBytes) {
        setofferfile(null);

        error.file = "File size exceeds the limit of 2 MB.";
      } else {
        setofferfile(selectedFile);

        error.file = "";
        reader.readAsDataURL(selectedFile);
      }
    } else {
      setofferfile(null);

      error.file = "Please Upload offer";
    }
  };

  const [showOfferImgTagModal, setShowOfferImgTagModal] = useState(false);
  const [showInnerBannerModal, setShowInnerBannerTagModal] = useState(false);
  const handleCloseOfferImgTagModal = () => {
    setShowOfferImgTagModal(false);
  };

  // Function to close Inner Banner modal
  const handleCloseInnerBannerTagModal = () => {
    setShowInnerBannerTagModal(false);
  };

  const handleToggleInnerBannerModal = () => {
    setShowInnerBannerTagModal((prevState) => !prevState);
  };

  const handleToggleOfferImgModal = () => {
    setShowOfferImgTagModal((prevState) => !prevState);
  };
  const handleSaveImageAltTag = (tagType, value) => {
    // Save the image alt tag value to state

    setFieldValue("offerImgTag", value);
    setShowOfferImgTagModal(false);

    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  const handleSaveInnerBannerTag = (tagType, value) => {
    // Save the inner banner tag value to state
    setFieldValue("innerBannerImgTag", value);
    setShowInnerBannerTagModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };
  let offerNameLength = 100;
  if (bwebsiteId === "TOC") {
    offerNameLength = 25;
  }

  if (!getAllResorts.data) {
    return <div>Loading....</div>;
  }
  // console.log("errors.discountText", errors.discountText);
  // console.log("touched.discountText", touched.discountText);
  // console.log("broucherFile", broucherFile);
  // console.log("websiteName", websiteName);
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-offers">
                    Manage Offers
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Offer
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to="/manage-offers"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Create Offer</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Create New</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <div className="row">
                      <div className="col-xl-9 col-lg-7">
                        <div className="col-lg-3">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="OfferType">
                              Copy From
                            </label>
                            <select
                              name="resortId"
                              id="OfferType"
                              className="form-control form-select py-3 shadow-none px-4"
                              onChange={handleOfferChange}
                            >
                              <option value="">
                                --- Select From Existing Offers ---
                              </option>
                              {allOffersData?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.offer_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <form action="#" method="get">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-4">
                                <div className="d-flex align-items-center">
                                  <label className="form-label mb-0">
                                    {" "}
                                    Image <span className="mandatory">*</span>
                                  </label>
                                  <a
                                    title="Add image alt tag"
                                    className="btn image-tag"
                                    onClick={() =>
                                      handleToggleOfferImgModal("OFFERIMGTAG")
                                    }
                                  >
                                    <HiPlus />
                                  </a>
                                </div>
                                {!maincroppedImage && !image && (
                                  <label
                                    ref={maincroppedFileRef}
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("OFFERIMAGE")
                                    }
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Upload image from gallery
                                      </button>
                                    </div>
                                  </label>
                                )}
                                {maincroppedImage && (
                                  <div
                                    className="uploaded-img-view"
                                    style={{
                                      width: "200px",
                                      position: "relative",
                                    }}
                                  >
                                    <div>
                                      <a
                                        href={maincroppedImage}
                                        target="_blank"
                                      >
                                        <img
                                          src={maincroppedImage}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                        }}
                                      >
                                        <button
                                          className="remove-image-button"
                                          onClick={() =>
                                            handleRemoveImage("OFFERIMAGE")
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {!maincroppedImage && image && (
                                  <div
                                    className="uploaded-img-view"
                                    style={{
                                      height: "200px",
                                      width: "200px",
                                      position: "relative",
                                    }}
                                  >
                                    <div>
                                      <a href={image} target="_blank">
                                        <img
                                          src={image}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                        }}
                                      >
                                        <button
                                          className="remove-image-button"
                                          onClick={() =>
                                            handleRemoveImage("OFFERIMAGE")
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {errors.offerfile && touched.offerfile ? (
                                  <p className="form-error text-danger">
                                    {errors.offerfile}
                                  </p>
                                ) : error.file ? (
                                  <p className="form-error text-danger">
                                    {error.file}
                                  </p>
                                ) : !errors.offerfile &&
                                  error.file &&
                                  touched.offerfile ? (
                                  <p className="form-error text-danger">
                                    Please select a file
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            {extraFields.includes("InnerBannerImage") && (
                              <div className="col-12 ">
                                <div className="mb-4">
                                  <div className="d-flex align-items-center">
                                    <label className="form-label mb-0">
                                      {" "}
                                      Inner Banner Image
                                      <span className="mandatory">*</span>
                                    </label>
                                    <a
                                      title="Add image alt tag"
                                      className="btn image-tag"
                                      onClick={(e) =>
                                        handleToggleInnerBannerModal(
                                          "INNERBANNERIMGTAG"
                                        )
                                      }
                                    >
                                      <HiPlus />
                                    </a>
                                  </div>
                                  {!innerBannerImage && (
                                    <label
                                      htmlFor="UploadCover"
                                      className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                      onClick={() =>
                                        showCropMediaModal("INNERBANNER")
                                      }
                                      ref={innerBannerRef}
                                      tabIndex={-1}
                                    >
                                      <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                        >
                                          Upload image from gallery
                                        </button>
                                      </div>
                                    </label>
                                  )}
                                  {innerBannerImage && (
                                    <div
                                      className="uploaded-img-view"
                                      style={{
                                        width: "200px",
                                        position: "relative",
                                      }}
                                    >
                                      <div>
                                        <a
                                          href={innerBannerImage}
                                          target="_blank"
                                        >
                                          <img
                                            src={innerBannerImage}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </a>
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                          }}
                                        >
                                          <button
                                            className="remove-image-button"
                                            onClick={() =>
                                              handleRemoveImage("INNERBANNER")
                                            }
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {bannerErrorMsg ? (
                                    <p className="form-error text-danger"></p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="OfferName"
                                >
                                  Offer Name{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  name="offerName"
                                  value={values.offerName}
                                  onChange={handleOfferNameChange}
                                  onBlur={handleBlur}
                                  maxLength={offerNameLength}
                                  ref={offerNameRef}
                                  type="text"
                                  id="OfferName"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.offerName && touched.offerName ? (
                                <p className="form-error text-danger">
                                  {errors.offerName}
                                </p>
                              ) : error.offerName ? (
                                <p className="form-error text-danger">
                                  {error.offerName}
                                </p>
                              ) : null}
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="OfferType"
                                >
                                  Resort <span className="mandatory">*</span>
                                </label>
                                <select
                                  ref={resortIdRef}
                                  name="resortId"
                                  id="OfferType"
                                  className="form-control form-select py-3 shadow-none px-4"
                                  onChange={handleChange}
                                  value={values.resortId}
                                >
                                  <option value="">---Select---</option>
                                  {getAllResorts.data.data.map((option) => (
                                    <option
                                      key={option.brand_resort_id}
                                      value={option.brand_resort_id}
                                    >
                                      {option.resort_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {errors.resortId && touched.resortId ? (
                                <p className="form-error text-danger">
                                  {errors.resortId}
                                </p>
                              ) : error.resortId ? (
                                <p className="form-error text-danger">
                                  {error.resortId}
                                </p>
                              ) : !errors.resortId &&
                                error.resortId &&
                                touched.resortId ? (
                                <p className="form-error text-danger">
                                  Please select a resort
                                </p>
                              ) : null}
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-2">
                                <label
                                  className="form-label"
                                  htmlFor="Discount"
                                >
                                  Discount(%)
                                </label>
                                <input
                                  name="discount"
                                  value={values.discount}
                                  min={0}
                                  step={1}
                                  maxLength={3}
                                  pattern="[0-9]"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  id="Discount"
                                  className="form-control py-3 shadow-none px-4"
                                  onKeyPress={(e) => {
                                    const isValidInput = /[0-9\b]/.test(e.key);
                                    if (!isValidInput) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-2">
                                <label
                                  className="form-label"
                                  htmlFor="Discount"
                                >
                                  Discount Text{" "}
                                  {mandatoryFields.includes("discountText") && (
                                    <span className="mandatory">*</span>
                                  )}
                                </label>
                                <input
                                  ref={discountTextRef}
                                  name="discountText"
                                  value={values.discountText}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={50}
                                  type="text"
                                  id="DiscountText"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.discountText && touched.discountText ? (
                                <p className="form-error text-danger">
                                  {errors.discountText}
                                </p>
                              ) : error.discountText ? (
                                <p className="form-error text-danger">
                                  {error.discountText}
                                </p>
                              ) : !errors.discountText &&
                                error.discountText &&
                                touched.discountText ? (
                                <p className="form-error text-danger">
                                  Please enter discount text
                                </p>
                              ) : null}
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="Description"
                                >
                                  Description{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <ReactQuill
                                  name={"description"}
                                  ref={descriptionRef}
                                  value={offerDesc}
                                  onChange={(e) => {
                                    handelDescChange(e);
                                  }}
                                  modules={modules}
                                  theme="snow"
                                  style={{ height: "200px", width: "100%" }} // Set height and width
                                />
                                {/* <textarea
                                                                    ref={descriptionRef}
                                                                    name="description"
                                                                    value={values.description}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    id="Description"
                                                                    className="form-control py-3 shadow-none px-4"
                                                                    cols="30"
                                                                    rows="5"
                                                                ></textarea> */}
                              </div>
                              {errors.description && touched.description ? (
                                <p className="form-error text-danger">
                                  {errors.description}
                                </p>
                              ) : error.description ? (
                                <p className="form-error text-danger">
                                  {error.description}
                                </p>
                              ) : !errors.description &&
                                error.description &&
                                touched.description ? (
                                <p className="form-error text-danger">
                                  Please enter offer description
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label className="form-label" htmlFor="Website">
                                  Book Now URL{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  ref={websiteRef}
                                  name="website"
                                  value={values.website}
                                  onChange={handleChange}
                                  maxLength={100}
                                  onBlur={handleBlur}
                                  type="url"
                                  id="Website"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.website && touched.website ? (
                                <p className="form-error text-danger">
                                  {errors.website}
                                </p>
                              ) : error.website ? (
                                <p className="form-error text-danger">
                                  {error.website}
                                </p>
                              ) : !errors.website &&
                                error.website &&
                                touched.website ? (
                                <p className="form-error text-danger">
                                  Please enter website
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="TermsConditions"
                                >
                                  Terms & Conditions{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <span className="toolIcon ms-2">
                                  <ToolLink
                                    title="Press 'Enter' to add a new bullet point"
                                    id="t-1"
                                  >
                                    <BsFillInfoCircleFill />
                                  </ToolLink>{" "}
                                </span>
                                <textarea
                                  ref={termsconditionsRef}
                                  name="termsconditions"
                                  value={values.termsconditions}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  rows="3"
                                  id="TermsConditions"
                                  className="form-control py-3 shadow-none px-4"
                                ></textarea>
                              </div>
                              {errors.termsconditions &&
                              touched.termsconditions ? (
                                <p className="form-error text-danger">
                                  {errors.termsconditions}
                                </p>
                              ) : error.termsconditions ? (
                                <p className="form-error text-danger">
                                  {error.termsconditions}
                                </p>
                              ) : !errors.termsconditions &&
                                error.termsconditions &&
                                touched.termsconditions ? (
                                <p className="form-error text-danger">
                                  Please enter termsconditions
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="Highlights"
                                >
                                  Highlights{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <span className="toolIcon ms-2">
                                  <ToolLink
                                    title="Press 'Enter' to add a new bullet point"
                                    id="t-1"
                                  >
                                    <BsFillInfoCircleFill />
                                  </ToolLink>{" "}
                                </span>

                                <textarea
                                  ref={highlightsRef}
                                  name="highlights"
                                  value={values.highlights}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  rows="3"
                                  id="Highlights"
                                  className="form-control py-3 shadow-none px-4"
                                ></textarea>
                              </div>
                              {errors.highlights && touched.highlights ? (
                                <p className="form-error text-danger">
                                  {errors.highlights}
                                </p>
                              ) : error.highlights ? (
                                <p className="form-error text-danger">
                                  {error.highlights}
                                </p>
                              ) : !errors.highlights &&
                                error.highlights &&
                                touched.highlights ? (
                                <p className="form-error text-danger">
                                  Please enter highlights
                                </p>
                              ) : null}
                            </div>
                            <div className="col-12 ">
                              <div className="mb-4">
                                <div className="d-flex align-items-center">
                                  <label className="form-label mb-0">
                                    Brochure
                                    {/* <span className="mandatory">*</span> */}
                                  </label>
                                </div>
                                {!broucherFile && (
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("BROUCHER")
                                    }
                                    // ref={innerBannerRef}
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Upload image from gallery
                                      </button>
                                    </div>
                                  </label>
                                )}
                                {broucherFile && (
                                  <div
                                    className="uploaded-img-view"
                                    style={{
                                      width: "200px",
                                      position: "relative",
                                    }}
                                  >
                                    <div>
                                      <a
                                        href={
                                          broucherFile.name === "file.pdf"
                                            ? URL.createObjectURL(broucherFile)
                                            : broucherFile
                                        }
                                        target="_blank"
                                      >
                                        <img
                                          src={pdfImg}
                                          style={{
                                            // width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                        }}
                                      >
                                        <button
                                          className="remove-image-button"
                                          onClick={() =>
                                            handleRemoveImage("BROUCHER")
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="StartDate"
                                >
                                  Offer Start Date{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  ref={startDateRef}
                                  type="date"
                                  selected={values.startDate}
                                  name="startDate"
                                  value={values.startDate}
                                  onChange={handleChange}
                                  id="StartDate"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.startDate && touched.startDate ? (
                                <p className="form-error text-danger">
                                  {errors.startDate}
                                </p>
                              ) : error.startDate ? (
                                <p className="form-error text-danger">
                                  {error.startDate}
                                </p>
                              ) : !errors.startDate &&
                                error.startDate &&
                                touched.startDate ? (
                                <p className="form-error text-danger">
                                  Please enter startDate
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="form-label" htmlFor="EndDate">
                                  Offer End Date{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  ref={endDateRef}
                                  type="date"
                                  selected={values.endDate}
                                  name="endDate"
                                  onChange={handleChange}
                                  value={values.endDate}
                                  id="EndDate"
                                  min={values.startDate || getCurrentDate()}
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.endDate && touched.endDate ? (
                                <p className="form-error text-danger">
                                  {errors.endDate}
                                </p>
                              ) : error.endDate ? (
                                <p className="form-error text-danger">
                                  {error.endDate}
                                </p>
                              ) : !errors.endDate &&
                                error.endDate &&
                                touched.endDate ? (
                                <p className="form-error text-danger">
                                  Please enter endDate
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-4">
                              <label className="form-label" htmlFor="Name">
                                Slug
                                <span className="mandatory">*</span>
                              </label>

                              <div className="bs-example">
                                <input
                                  onChange={handleChange}
                                  ref={slugRef}
                                  onBlur={handleBlur}
                                  type="text"
                                  maxLength={100}
                                  name="slugName"
                                  value={values?.slugName?.trim()}
                                  id="slugName"
                                  className="form-control py-3 shadow-none px-4"
                                  data-role="tagsinput"
                                  onKeyPress={handleKeyPress}
                                />
                              </div>
                              {errors.slugName && touched.slugName ? (
                                <p className="form-error text-danger">
                                  {errors.slugName}
                                </p>
                              ) : error.slugName ? (
                                <p className="form-error text-danger">
                                  {error.slugName}
                                </p>
                              ) : null}
                            </div>

                            {websiteName === "Kolkata" && (
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label
                                    className="form-label"
                                    htmlFor="OfferType"
                                  >
                                    Offer Types
                                    <span className="mandatory">*</span>
                                  </label>
                                  <select
                                    // ref={resortIdRef}
                                    name="offerTypeId"
                                    id="OfferType"
                                    className="form-control form-select py-3 shadow-none px-4"
                                    onChange={handleChange}
                                    value={values.offerTypeId}
                                  >
                                    <option value={"0"}>---Select---</option>
                                    {kolkatofferTypes?.data?.data?.map(
                                      (option) => (
                                        <option
                                          key={option.offer_type_id}
                                          value={option.offer_type_id}
                                        >
                                          {option.offer_type}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            )}
                            {websiteCode === "SADARMANZIL" && (
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label
                                    className="form-label"
                                    htmlFor="OfferType"
                                  >
                                    Offer Types
                                    <span className="mandatory">*</span>
                                  </label>
                                  <select
                                    // ref={resortIdRef}
                                    name="offerTypeId"
                                    id="OfferType"
                                    className="form-control form-select py-3 shadow-none px-4"
                                    onChange={handleChange}
                                    value={values.offerTypeId}
                                  >
                                    <option value={"0"}>---Select---</option>
                                    {kolkatofferTypes?.data?.data?.map(
                                      (option) => (
                                        <option
                                          key={option.offer_type_id}
                                          value={option.offer_type_id}
                                        >
                                          {option.offer_type}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            )}

                            {/* meta and og code  */}
                            <div className="col-12">
                              <ul
                                className="nav nav-pills atmos-tabs d-flex flex-wrap pt-3 border-bottom pb-3"
                                id="myTab"
                                role="tablist"
                              >
                                {/* meta tags  */}
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link text-uppercase active"
                                    id="highlight-extra-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#meta-tags"
                                    type="button"
                                    role="tab"
                                    aria-controls="highlight-extra-tab-pane"
                                    aria-selected="false"
                                  >
                                    Meta Tags
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link text-uppercase"
                                    id="highlight-extra-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#Open-Graph"
                                    type="button"
                                    role="tab"
                                    aria-controls="highlight-extra-tab-pane"
                                    aria-selected="false"
                                  >
                                    OG Tags
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div
                              className="tab-content mt-4 mb-0"
                              id="myTabContent"
                            >
                              <div
                                className="tab-pane fade"
                                id="Open-Graph"
                                role="tabpanel"
                                aria-labelledby="Open-Graph"
                                tabIndex="0"
                              >
                                <OGtags
                                  handelChange={handleChange}
                                  values={values}
                                  ogImage={ogImagePlaceholer}
                                  setOgimage={setOgImage}
                                  showCropMediaModal={showCropMediaModal}
                                />
                              </div>
                              <div
                                className="tab-pane fade show active"
                                id="meta-tags"
                                role="tabpanel"
                                aria-labelledby="Amenities-tab"
                                tabIndex="0"
                              >
                                <div className="col-md-12 mb-4">
                                  <label className="form-label">
                                    Keywords{" "}
                                  </label>
                                  <input
                                    name="keyWords"
                                    value={values.keyWords}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    id="Keywords"
                                    className="form-control py-3 shadow-none px-4"
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      className="form-label"
                                      // htmlFor="OfferName"
                                    >
                                      Meta Title{" "}
                                    </label>
                                    <input
                                      name="metaTitle"
                                      value={values.metaTitle}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      id="metaTitle"
                                      className="form-control py-3 shadow-none px-4"
                                      maxLength={70}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      className="form-label"
                                      // htmlFor="OfferName"
                                    >
                                      Meta Description{" "}
                                    </label>
                                    <input
                                      name="metaDescription"
                                      value={values.metaDescription}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      id="metaDescription"
                                      className="form-control py-3 shadow-none px-4"
                                      maxLength={170}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 text-end mt-lg-4 mt-0">
                              {bwebsiteId === "TOC" && (
                                <Button
                                  // href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setAction("PREVIEW");
                                    // handleSubmit();
                                    handelFocus();
                                  }}
                                  className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                                  disabled={isPreviewLoading}
                                >
                                  {isPreviewLoading ? (
                                    <div
                                      class="spinner-border text-primary"
                                      role="status"
                                    ></div>
                                  ) : (
                                    "PREVIEW"
                                  )}
                                </Button>
                              )}
                              <Button
                                type="submit"
                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setAction("SAVE");
                                  // handleSubmit();
                                  handelFocus();
                                }}
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <div
                                    class="spinner-border text-primary"
                                    role="status"
                                  ></div>
                                ) : (
                                  "SAVE"
                                )}
                              </Button>
                              {/* <input
                                onClick={handleSubmit}
                                type="submit"
                                value="Save"
                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                              /> */}
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-xl-3 col-lg-5 mt-lg-0 mt-4">
                        <div className="card mb-4">
                          <div className="card-body">
                            <div className="Locales border-top mt-4 pt-3">
                              <h6 className="fw-bold">Featured To</h6>
                              <label
                                htmlFor="FeaturedCheck"
                                className="position-relative d-flex"
                              >
                                <input
                                  type="checkbox"
                                  onChange={handlecheckboxChange}
                                  name="featuredHR"
                                  id="FeaturedCheck"
                                  checked={
                                    values.featuredHR === true ||
                                    values.featuredHR === false
                                      ? values.featuredHR
                                      : values.featuredHR === 1
                                      ? true
                                      : false
                                  }
                                />
                                <span className="ms-2 text-muted">
                                  Group Website
                                </span>
                              </label>
                              <label
                                htmlFor="FeaturedKanifushi"
                                className="position-relative d-flex"
                              >
                                <input
                                  type="checkbox"
                                  onChange={handlecheckboxChangeR}
                                  name="featuredKF"
                                  id="FeaturedKanifushi"
                                  checked={
                                    values.featuredKF === true ||
                                    values.featuredKF === false
                                      ? values.featuredKF
                                      : values.featuredKF === 1
                                      ? true
                                      : false
                                  }
                                />
                                <span className="ms-2 text-muted">
                                  Resort Website
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewSelection
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedImage={setmainCroppedImage}
              setmainCroppedFile={
                offerValForBanner === "OGIMAGE"
                  ? setOgImage
                  : setmainCroppedFile
              }
              setInnerBannerFile={setInnerBannerFile}
              setInnerBannerImage={setInnerBannerImage}
              offerValForBanner={offerValForBanner}
              broucherValue={broucherValue}
              setBroucherFile={setBroucherFile}
              // setting_value={
              //   getAspectRatio &&
              //   getAspectRatio.data &&
              //   getAspectRatio.data.data &&
              //   getAspectRatio.data.data.setting_value
              // }
              setting_value={settingValue}
            />
          )}
        </Modal.Body>
      </Modal>

      <ImageTagModal
        showModalImageTagModal={showOfferImgTagModal}
        handleCloseImageTagModal={handleCloseOfferImgTagModal}
        initialImageAltTag={values.offerImgTag}
        tagType="OFFERIMGTAG"
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="offerImgTag"
      />
      {/*condition */}
      {extraFields.includes("InnerBannerImage") && (
        <ImageTagModal
          showModalImageTagModal={showInnerBannerModal}
          handleCloseImageTagModal={handleCloseInnerBannerTagModal}
          initialImageAltTag={values.innerBannerImgTag}
          tagType="INNERBANNERIMGTAG"
          handleSaveImageAltTag={handleSaveInnerBannerTag}
          inputName="innerBannerImgTag"
        />
      )}
    </>
  );
};

export default CreateOffers;
