import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import forgotLogo from "../../assets/images/logo-circle.svg";
import forgotLogoAhr from "../../assets/images/logo-circle - ahr-plain.svg"

import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as querydata from "../dataService/DataService";
import { GlobalContext } from "../../globalContext";
import { useContext } from "react";

// Login Validation
export const loginSchema = Yup.object({
  email: Yup.string().required("Please enter Email Address"),
});

const ForgotPassword = () => {
  const { bwebsiteId } = useContext(GlobalContext); 
  // Initialize Navigate method
  // const navigate = useNavigate();

  // Values defined for Formik
  const initialValues = {
    email: "",
    password: "",
  };

  const [showPassword, setShowPassword] = useState(false);

  // const toggleShowPassword = (e) => {
  //   e.preventDefault();
  //   setShowPassword(!showPassword);
  // };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: (values, action) => {
        forgotPasswordMutation.mutate(values);
      },
    });

  const forgotPasswordMutation = useMutation(querydata.forgotPassword, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success("Email has been sent to you with password reset link.");
    },
  });

  return (
    <>
      <div className="login-wrapper position-relative d-flex align-items-center">
        <form className="w-100 m-auto rounded-2 position-relative z-2 bg-white pb-4">
          <div className="login-logo forgot-logo px-3 py-4 text-center">
            {bwebsiteId === "AHR" ? (

              <img src={forgotLogoAhr} alt="Atmosphere" title="Atmosphere" />
            ) : (
              <img src={forgotLogo} alt="Atmosphere" title="Atmosphere" />
              
              
            )}
            <h4 className="mt-4">Forgot Password</h4>
          </div>
          <div className="login-fields px-lg-5 px-4">
            <div className="mb-4">
              <input
                maxLength={50}
                type="email"
                placeholder="Enter email associated with UCMS"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                id="Email"
                className="form-control shadow-none"
              />
              {errors.email && touched.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>

            <div className="mb-4">
              {forgotPasswordMutation.isLoading ? (
                <button
                  className="ms-auto btn btn-primary btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-login-btn"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden" role="status">
                    Loading...
                  </span>
                </button>
              ) : (
                <button
                  id="login-btn"
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-3 w-100"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
