import React from 'react';
import { Chart } from 'react-google-charts';

const ColumnChartComponent = ({ data }) => {
  // Transform the data into the format required by Google Charts
  const filteredData = data?.filter(item => item.ageBracket !== 'unknown');

  // Transform the filtered data into the format required by Google Charts
  const chartData = [
    ['Age Bracket', 'Active Users'], // Header row
    ...filteredData?.map(item => [item.ageBracket, item.activeUsers])
  ];


  // Define chart options
  const options = {
  //  title: 'Active Users by Age Bracket in the United Kingdom',
    chartArea: { width: '50%' },
    hAxis: {
      title: 'Age Bracket',
    },
    vAxis: {
      title: 'Active Users',
      minValue: 0,
    },
    legend: { position: 'none' }, // No legend needed
    colors: ['#987BEC'], // Array of colors for slices

  };

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="250px"
        data={chartData}
        options={options}
        loader={<div>Loading Chart...</div>}
      />
    </div>
  );
};

export default ColumnChartComponent;
