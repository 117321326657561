import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const RestaurentTags = (
  {
    initialValues,
    distinctValue = false,
    className,
    name,
    value = [],
    setFieldValue,
    values,
    formik
  },
  ref
) => {

  //   const { setFieldValue } = form;
  const [inputCount, setInputCount] = useState(
    // value.length
    // value && value.length === 0 ? 1 : value.length
    values && values?.RestaurantTags?.length === 0
      ? 1
      : values?.RestaurantTags?.length
  );
  if (inputCount === 0) {
    setInputCount(1);
  } else {
  }
  const [selectedValues, setSelectedValues] = useState(values?.RestaurantTags);
  useEffect(() => {

    setInputCount(values?.RestaurantTags?.length);
    setSelectedValues(values?.RestaurantTags);
  }, [values?.RestaurantTags?.length]);

  const handleInputChange = (index, inputValue) => {
    let updated = [...selectedValues]

    updated[index] = inputValue
    setInputCount(updated.length)

    setSelectedValues(updated)
    // updated[0] = "12anuj"
    formik.resetForm({
      values: {
        ...formik.values,

        RestaurantTags: updated
      },
    });
  };
 


  const handleAddClick = () => {
    // if (selectedValues[inputCount - 1] !== "") {
    //   setInputCount((prevCount) => prevCount + 1);

    // } else {
    //   toast.error(
    //     "Please enter a value in the current input before adding a new one."
    //   );
    // }

    let update = [...selectedValues]
    if (update[inputCount - 1] === undefined || "") {
      toast.dismiss()
      toast.error(
        "Please enter a value in the current input before adding a new one."
      );

    }
    else {
      setInputCount((prevCount) => prevCount + 1);
    }


  };


  const handleRemoveClick = (index) => {
    setInputCount((prevCount) => prevCount - 1);
    let update = [...selectedValues]
    update.splice(index, 1)
    setSelectedValues(update)
    formik.resetForm({
      values: {
        ...formik.values,

        RestaurantTags: update
      },
    });



  };

  return (
    <>
      {Array.from({ length: inputCount }, (_, index) => (
        <div className="bed-row d-flex flex-wrap align-items-end" key={index}>
          <div className="bed-add-row d-flex flex-wrap w-100">
            <div className="select-bed-col mb-4">
              <input
                type="text"
                name={`input_${index}`}
                id={`input_${index}`}
                value={value[index]}
                onChange={(e) => handleInputChange(index, e.target.value)}

                className="py-3 shadow-none px-4 form-control"
              />
            </div>

            {index === inputCount - 1 && (
              <>
                <div className="add-more-blocks text-end mb-4">
                  <a
                    onClick={() => { handleAddClick() }}
                    className="btn btn-primary btn-theme fw-semibold text-uppercase"
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </a>
                </div>
              </>
            )}
            {index !== inputCount - 1 && (
              <>
                <div className="add-more-blocks text-end mb-2">
                  <a
                    onClick={() => handleRemoveClick(index)}
                    className="btn btn-outline-danger text-uppercase"
                  >
                    <i className="fa fa-minus"></i>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      ))}

    </>
  );
};

export default React.forwardRef(RestaurentTags);
