import React from 'react';
import Select from 'react-select';

// CustomOption component for rendering the label with Edit and Delete buttons
const CustomOption = ({ data, onEdit, onDelete }) => (
  <div className="gap-0">
    <div className='d-flex justify-content-between align-items-center option-container border-bottom py-2'>
    <span className="option-label ps-3 pe-1">{data?.label}</span>
    <div className="option-buttons d-flex flex-nowrap pe-3">
      {/* Edit Button */}
      <span
        className="edit-btn btn btn-primary btn-sm btn-theme px-1 "
        onClick={(e) => {
          e.stopPropagation(); // Prevent option from being selected
          onEdit(data?.value); // Trigger edit action
        }}
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </span>
      {/* Delete Button */}
      <span
        className="delete-btn btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1 px-1"
        onClick={(e) => {
          e.stopPropagation(); // Prevent option from being selected
          onDelete(data); // Trigger delete action
        }}
      >
        <i className="fa fa-trash-can" aria-hidden="true"></i>
      </span>
    </div>
    </div>
  </div>
);

// CustomSelect component that renders the options with Edit/Delete buttons
const RestaurantSelect = ({
  values,
  setFieldValue,
  handleBlur,
  AllRestaurantTypeId,
  handleEdit,
  handleDelete,
}) => {
  // Ensure options is defined
  const options = AllRestaurantTypeId?.data?.data?.map((option) => ({
    value: option.restaurant_type_id,
    label: option.restaurant_type,
  })) || []; // Default to empty array if data is not available

  // Render the custom option with buttons inside react-select
  const customSingleValue = ({ data }) => <div>{data.label}</div>; // Only display label in the select input box

  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} className="custom-option">
        <CustomOption data={data} onEdit={handleEdit} onDelete={handleDelete} />
      </div>
    );
  };

  // Compact custom styles for react-select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '4px 8px', // Compact padding
      borderRadius: '4px', // Smaller radius
      boxShadow: state.isFocused ? '0 0 0 1px #4CAF50' : 'none',
      borderColor: state.isFocused ? '#4CAF50' : '#ced4da',
      '&:hover': {
        borderColor: '#4CAF50',
      },
      fontSize: '14px', // Smaller font size
      minHeight: '38px', // Height similar to standard select
      backgroundColor: '#ffffff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#e6f7ff' : 'white',
      color: state.isSelected ? 'white' : 'black',
      padding: '8px 12px', // Smaller padding for options
      cursor: 'pointer',
      
      '&:hover': {
        backgroundColor: '#e6f7ff',
        color: 'black',
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px', // Compact dropdown corners
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '14px', // Smaller font size for selected value
      color: '#495057',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px', // Compact dropdown arrow
      color: '#4CAF50',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  return (
    <Select
    className='cstm-select'
      options={options}
      value={options?.find((opt) => opt?.value === values?.RestaurantTypeId) || null} // Handle cases where RestaurantTypeId is not found
      onChange={(selected) => setFieldValue('RestaurantTypeId', selected?.value)} // Handle selection
      onBlur={handleBlur} // Handle blur event
      components={{ Option: customOption, SingleValue: customSingleValue }} // Custom components for option and selected value
      isClearable={true} // Allow clearing selection
      isSearchable={false} // Disable search functionality
      styles={customStyles} // Apply compact custom styles
    />
  );
};

export default RestaurantSelect;
