import React from 'react'
import { useMutation, useQuery, useQueryClient } from "react-query";

import Modal from "react-bootstrap/Modal";

import { Button } from 'react-bootstrap';
const AlbumMadalComponent = ({
    albumHeading = "",
    show = '',
    handleClose = '',
    initialValues = "",
    values = "",
    errors = "",
    touched = "",
    handleChange = "",
    handleBlur = "",
    handleSubmit = "",
    isLoading = "",
}) => {

    
    return (
        <>
            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header className="modal-header px-5 py-4" closeButton>
                    <Modal.Title className="modal-title fs-5">{albumHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3">
                        <div className="upload-file-wrapper">
                            <form>
                                <div className="row">
                                    {
                                        <div className="col-12 mb-4">
                                            <label className="form-label" htmlFor="Name">
                                                Album Title
                                            </label>
                                            <div className="mb-4">
                                                <input
                                                    type="text"
                                                    name="albumTitle"
                                                    value={values?.albumTitle}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control py-3 shadow-none px-4"
                                                    maxLength={80}
                                                />
                                                {errors.albumTitle && touched.albumTitle && (
                                                    <p className="form-error text-danger">
                                                        {errors.albumTitle}
                                                    </p>
                                                )}
                                                {/* {errors.albumTitle && touched.albumTitle ? (
                                                    <p className="form-error text-danger">
                                                        {errors.albumTitle}
                                                    </p>
                                                ) : errors.albumTitle ? (
                                                    <p className="form-error text-danger">
                                                        {errors.albumTitle}
                                                    </p>
                                                ) : null} */}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="col-12 pt-5 text-end mb-2">
                                    <Button
                                        onClick={handleClose}
                                        data-bs-dismiss="modal"
                                        value="Cancel"
                                        className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-2 me-1"
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        type="submit"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                        onClick={handleSubmit}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <div
                                                className="spinner-border text-primary btn-theme fw-semibold"
                                                role="status"
                                            ></div>
                                        ) : (
                                            "SAVE"
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AlbumMadalComponent
