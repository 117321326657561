import React from "react";
import sideBarData from '../dataService/sidebar_data.json';
import { Link } from 'react-router-dom';
 import SideBarRaiseTicket from "../../layouts/SideBarRaiseTicket";
function ManageMenu() {

    return (
        <>            
            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex align-items-center justify-content-between">
                        <h4 className="fw-normal m-0">Welcome to <strong>Atmosphere H & R</strong></h4>
                        <div className="last-login">
                            <span><i className="fa-solid fa-circle-info me-2"></i> Last Login ~Admin from 128.1.5.168</span>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-md-4 mt-3">
                        <div className="col-xxl-9">
                            <ul className="menu-cards list-unstyled mt-0 mb-0 p-0 d-grid">
                            {sideBarData.map(menuData => (
                                <li key={menuData.menuId}>
                                    <Link to={menuData.path} className="card text-decoration-none">
                                        <div className="card-body text-center">
                                            <div className="icon-nav">
                                                <i className={`${menuData.icon}`} aria-hidden="true"></i>
                                            </div>
                                            <div className="card-title">
                                                <h6 className="m-0 fw-normal">{menuData.menuName}</h6>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                ))}
                            </ul>
                        </div>
                       <SideBarRaiseTicket/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageMenu
