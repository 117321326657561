import { useEffect, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from "@dnd-kit/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Column } from "./Column";
import { toast } from "react-toastify";
const Tabs = ({
  data,
  handleShowEditSubSectionModal,
  handleShowModal,
  handleReorderSubSections,
  deleteBtnShow,
  updateSortingTabs,
  changeSortingIndexMutation,
  setActiveTab,
  activeTab
}) => {
  // const [tasks, setTasks] = useState([
  //   { id: 1, title: "Add tests to homepage" },
  //   { id: 2, title: "Fix styling in about section" },
  //   { id: 3, title: "Learn how to center a div" },
  // ]);

 
  useEffect(() => {
    if (data) {
      setTasks(data);
    }
  }, [data]);
  const queryClient = useQueryClient();
  const [tasks, setTasks] = useState(data || []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getTaskPos = (id) => tasks.findIndex((task) => task.id === id);
  const invalidateQuery = ""; // Change this text
  const handleDragEnd = (event) => {
   
    const { active, over } = event;

    if (active.id === over.id) return;

    setTasks((tasks) => {
      const originalPos = getTaskPos(active.id);
      const newPos = getTaskPos(over.id);
       handleReorderSubSections(originalPos,newPos)
      changeSortingIndexMutation.mutate({
        id: active.id,
        newSortingIndex: newPos + 1,
      });

      return arrayMove(tasks, originalPos, newPos);
    });
  };
 

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
    >
      <Column
        id="toDo"
        tasks={tasks}
        handleShowEditSubSectionModal={handleShowEditSubSectionModal}
        handleShowModal={handleShowModal}
        setActiveTab={setActiveTab}
      />
    </DndContext>
  );
};

export default Tabs;