import React, { useEffect, useState, useCallback } from "react";
import { getAllAmenities } from "../../services/service";
import {
  insertAmenity,
  getSingleAmenityData,
  updateAmenityData,
  deleteSingleAmenity,
} from "../dataService/DataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Modal from "react-bootstrap/Modal";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AmenityModal from "./AmenityModal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import AmenityDragDrop from "./components/amenityDragAbleComponents/AmenityDragDrop";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  rectSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import ConfirmationModal from "./ConfirmationModal";

export const amenitySchema = Yup.object({
  amenityName: Yup.string().trim().required("Please enter amenity name"),
});
function AmenitiesTab1({
  setFieldValue,
  setAmenityCropImage,
  showCropMediaModal,
  amenityFileData,
  setAmenityFile,
  amenitiesMyData,
  field,
  amenityCropImage,
  setAmenitySortingIds = "",
  setSortedAmenity,
  sortedAmenity,
}) {
  // console.log("amenitiesMyDataamenitiesMyData  : ", amenitiesMyData);
  const ToolLink = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="text-dark text-decoration-none">{children}</a>
    </OverlayTrigger>
  );
  const truncateText = (text = "", length) => {
    return text?.length > +length ? text.slice(0, +length) + "..." : text;
  };

  const [modalTitle, setModalTitle] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const [error, setError] = useState({});
  const { id } = useParams();
  const [amenityId, setAmenityId] = useState(null);
  const { name, value } = field;
  const [aminityLoading, setAminityLoading] = useState(false);
  const [aminitysort, setamenitySort] = useState([]);
  const [amty, setAmty] = useState(false);
  // const [sortedAmenity, setSortedAmenity] = useState([]);
  // const [checkboxCount, setCheckboxCount] = useState(amenitiesMyData.length);

  const [selectedValues, setSelectedValues] = useState(
    amenitiesMyData ? amenitiesMyData : []
  );
  useEffect(() => {
    // Update the Formik field value whenever  change
    const filterSelectedValues = selectedValues?.filter(
      (item) => item !== 0 && item !== ""
    );
    setFieldValue(name, filterSelectedValues);
  }, [selectedValues?.length, setFieldValue, name]);

  let AllAmenities = useQuery("getAllAmenities", getAllAmenities);
  // const filteredAmenityData =
  //   AllAmenities?.data?.data.filter((item) =>
  //     selectedValues.includes(item.amenity_id)
  //   ) || [];
  const filteredAmenityData = (AllAmenities?.data?.data || [])
    .filter((item) => selectedValues.includes(item.amenity_id))
    .sort((a, b) => {
      // Sorting based on the index in selectedValues
      return (
        selectedValues.indexOf(a.amenity_id) -
        selectedValues.indexOf(b.amenity_id)
      );
    });

 // Initialize `sortedAmenity` on mount or when `amenitiesData` is updated
 useEffect(() => {
  if (AllAmenities?.data?.data && sortedAmenity === undefined) {
    // Pre-filter amenities based on selectedValues
    const initialSortedAmenities = AllAmenities?.data?.data?.filter((item) =>
      selectedValues?.includes(item.amenity_id)
    ).sort((a, b) => {
      // Sorting based on the index in selectedValues
      return (
        selectedValues?.indexOf(a.amenity_id) -
        selectedValues?.indexOf(b.amenity_id)
      );
    });

    setSortedAmenity(initialSortedAmenities); // Set the initial sorted list
  }
}, [AllAmenities?.data?.data?.length, selectedValues, setSortedAmenity, sortedAmenity]);

  // console.log("selected values   :", filteredAmenityData);

  // console.log("Filtered Data", filteredAmenityData);
  // console.log("filterSelectedValues", selectedValues)
  // console.log("sortedAmenity", sortedAmenity);
  
  // const handleCheckboxChange = (amenityId, amenity) => {
  //   setSortedAmenity((prev) => {
  //     // Check if `prev` is null or undefined
  //     if (!prev) {
  //       return [amenity]; // Initialize with the new amenity
  //     }
  //     return [...prev, amenity]; // Append to existing array
  //   });

  
  //   setSelectedValues((prevValues) => {
  //     // Check if the amenityId is already in the array

  //     if (prevValues.includes(amenityId)) {
  //       // Remove the amenityId if it's present

  //       const preVal = prevValues.filter((id) => id !== amenityId);
  //       setAmenitySortingIds(preVal);
  //       return preVal;
  //     } else {
  //       // Add the amenityId if it's not present
  //       return [...prevValues, amenityId];
  //     }
  //   });
  // };

  const handleCheckboxChange = (amenityId, amenity) => {
    setSortedAmenity((prev) => {
      const isAlreadySelected = prev.some((item) => item.amenity_id === amenityId);
      if (isAlreadySelected) {
        // Remove from sortedAmenity
        return prev.filter((item) => item.amenity_id !== amenityId);
      } else {
        // Append at the end, preserving the current custom order
        return [...prev, amenity];
      }
    });
  
    setSelectedValues((prevValues) => {
      if (prevValues.includes(amenityId)) {
        // Remove the amenityId if it's present
        const updatedValues = prevValues.filter((id) => id !== amenityId);
        setAmenitySortingIds(updatedValues);
        return updatedValues;
      } else {
        // Add the amenityId
        return [...prevValues, amenityId];
      }
    });
  };
  

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
    setAmenityFile("");
  };
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleShowAmentieModal = (amenity_id, e, val) => {
    if (e) {
      e.preventDefault();
    }

    setAmenityId(amenity_id);
    setShow(true);
    setAmenityFile("");

    if (val === "edit") {
      setModalTitle("Edit Amenity");
      setModalHeading("Edit");
    } else {
      setModalTitle("Add New Amenity");
      setModalHeading("Add amenity");
    }
  };
  const getSigleAmenity = useQuery(["getSigleAmenity", +amenityId], () =>
    getSingleAmenityData(amenityId)
  );
  
  const editableAmenity = getSigleAmenity?.data?.is_editable;
   const [forceEdit, setForceEdit] = useState(null); // Initialize with a neutral state
    
    // Sync forceEdit state with editableAmenity when it changes
    useEffect(() => {
      if (editableAmenity !== undefined) {
        setForceEdit(editableAmenity);
      }
    }, [editableAmenity]);
  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const handleAmenityChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setCheckBoxArray([...checkBoxArray, value]);
    } else {
      setCheckBoxArray(checkBoxArray.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    // setFieldValue("amenitiesId", checkBoxArray);
  }, [checkBoxArray, setFieldValue]);

  const initialValues = {
    amenityName: getSigleAmenity?.data?.data?.amentity_name || "",
    amenityFromEditIcon: getSigleAmenity?.data?.data?.icon_path || "",
  };
const [confirmationModal,setConfirmationModal]=useState(false);

  // const amenityIcon =
  //   amenityFileData &&
  //   amenityFileData.data &&
  //   amenityFileData.data.media_file_path;
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: amenitySchema,
    onSubmit: (values, action) => {

      try {
        setAminityLoading(true);
        const formData = new FormData();
        formData.append("amenityName", values.amenityName);
        if (amenityFileData) {
          formData.append("amenityIcon", amenityFileData);
        } else {
          formData.append("amenityIcon", values.amenityFromEditIcon);
        }
        if (amenityId > 0) {
          if(!forceEdit){
            setConfirmationModal(true);
            setShow(false);
          }else{
          updateAmentiesMutation.mutate({ formData, amenityId });
          }
        } else {
          addAmentiesMutation.mutate(formData);
        }
      } catch (error) {
        setAminityLoading(false);
      }
    },
  });

  const updateAmentiesMutation = useMutation(updateAmenityData, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setAminityLoading(false);
      toast.dismiss();
      toast.success(data.message);
      setConfirmationModal(false);
      setForceEdit(editableAmenity);
      queryClient.invalidateQueries("getAllAmenities");
      queryClient.invalidateQueries("getSigleAmenity");
      setValues(initialValues);
      setShow(false);
      setAmenityFile("");
      // resetForm();
      setAmenityCropImage(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const addAmentiesMutation = useMutation(insertAmenity, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setAminityLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate(`/manage-properties/edit-property/${id}`);
      queryClient.invalidateQueries("getAllAmenities");
      queryClient.invalidateQueries("getSigleAmenity");
      setValues(initialValues);
      setShow(false);
      setAmenityFile("");
      resetForm();
      setAmenityCropImage(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  useEffect(() => {
    if (getSigleAmenity.isSuccess) {
      setValues((prevValues) => ({
        ...prevValues,
        amenityName: getSigleAmenity.data?.data?.amentity_name || "",
        amenityFromEditIcon: getSigleAmenity.data?.data?.icon_path || "",
      }));
    }
  }, [getSigleAmenity.data, setValues]);

  const handleAmenityDelete = (amenityId, e) => {
    if (e) {
      e.preventDefault();
    }
    amenityDelete.mutate(amenityId);
  };
  const amenityDelete = useMutation(deleteSingleAmenity, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getAllAmenities");
    },
  });

  // useEffect(() => {
  //   (() => {
  //     if (show === false) {
  //       resetForm();
  //     }
  //   })();
  // }, [show]);
  // const [unselectedAmenities, setUnselectedAmenities] = useState([]);

  let unselectedAmenities = AllAmenities?.data?.data?.filter(
    (item) => !selectedValues.includes(item.amenity_id)
  );

  const [amenitesItems, setAmenitesItems] = useState([]);

  useEffect(() => {
    setAmenitesItems(filteredAmenityData);
  }, [filteredAmenityData?.length]);

  // const handleDragEnd = async (event) => {
  //   const { active, over } = event;

  //   if (over) {
  //     const oldIndex = amenitesItems?.findIndex((item) => item.amenity_id === active.id);
  //     const newIndex = amenitesItems?.findIndex((item) => item.amenity_id === over.id);
  //     // Check if the position is the same
  //     if (oldIndex === newIndex) {
  //       return;
  //     }
  //     const updatedItems = arrayMove(amenitesItems, oldIndex, newIndex);
  //     setAmenitesItems(updatedItems); // Update local state
  //     // Send only the dragged item's ID and its new index
  //     const draggedItem = updatedItems[newIndex];
  //     console.log("draggedItem",draggedItem)
  //     // Create the payload for the API (dragged item and new sorting index)
  //     const amenityIds = {
  //       amenityId: draggedItem,
  //       newSortingIndex: (newIndex + 1).toString(), // Indexing starts from 1 for the backend
  //     };
  //     setAmenitySortingIds(amenityIds)
  //      console.log("amenityIds",amenityIds)
  //   }
  // };
  // Drag and Drop sensors
  // console.log("inside", amty);

  // useEffect(() => {
  //   if (!amty) {
  //     const filteredAmenities = AllAmenities?.data?.data.filter((amenity) =>
  //       selectedValues.includes(amenity.amenity_id)
  //     );
  //     console.log("filter data  : ", filteredAmenities);
  //     setSortedAmenity(filteredAmenities);
  //   }
  // }, [selectedValues]);
  useEffect(() => {
    if (!amty) {
      const filteredAmenities = AllAmenities?.data?.data?.filter((amenity) =>
        selectedValues.includes(amenity.amenity_id)
      );
  
      // Sort filteredAmenities based on the order in selectedValues
      const sortedAmenities = filteredAmenities?.sort(
        (a, b) =>
          selectedValues.indexOf(a.amenity_id) - selectedValues.indexOf(b.amenity_id)
      );  
      
      setSortedAmenity(sortedAmenities);
    }
  }, [selectedValues?.length]);

  // console.log("all amenity selectedValues : ", selectedValues);
  // console.log("all amenity  333: ",sortedAmenity)

  const handleDragEnd = async (event) => {
    setAmty(true);
    const { active, over } = event;

    if (over) {
      const oldIndex = amenitesItems?.findIndex(
        (item) => item.amenity_id === active.id
      );
      const newIndex = amenitesItems?.findIndex(
        (item) => item.amenity_id === over.id
      );

      // Check if the position is the same
      if (oldIndex === newIndex) {
        return;
      }

      // Rearrange items based on drag-and-drop
      const updatedItems = arrayMove(amenitesItems, oldIndex, newIndex);

      // console.log("new updated array   : ", updatedItems);

      // Update sorting index for all items in the array
      const updatedWithSortingIndex = updatedItems?.map((item, index) => ({
        ...item,
        // sortingIndex: index + 1, // Add 1 to make it 1-based indexing
      }));
      setAmenitesItems(updatedWithSortingIndex); // Update local state
      setSortedAmenity(updatedItems);
      // Extract the dragged item and its new sorting index
      const draggedItem = updatedWithSortingIndex?.find(
        (item) => item.amenity_id === active.id
      );

      // console.log("draggedItem", draggedItem);
      const amenityIds = updatedWithSortingIndex?.map((item) => ({
        amenityId: item.amenity_id,
        newSortingIndex: item.sortingIndex,
      }));
      // const amenityIds = updatedWithSortingIndex.map((item) => item.sortingIndex);

      // Create the payload for the API
      // const amenityIds = {
      //   amenityId: draggedItem.amenity_id,
      //   newSortingIndex: draggedItem.sortingIndex.toString(),
      // };

      setAmenitySortingIds(amenityIds); // Update API payload
      // console.log("amenityIds", amenityIds);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <>
      <div className="edit-mein-info">
        {sortedAmenity?.length > 0 && (
          <>
            <div className="top-header-edit">
              <h5>Selected Amenities</h5>
            </div>
            <div className="row mt-4 row-gap-4 mb-4">
              <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
                <SortableContext
                  items={sortedAmenity?.map((item) => item.amenity_id)}
                  strategy={rectSortingStrategy}
                >
                  {sortedAmenity?.map((item, index) => {
                    return (
                      <AmenityDragDrop
                        handleDragComponentCheckboxChange={handleCheckboxChange}
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        item={item}
                        index={index}
                        setSortedAmenity={setSortedAmenity}
                      />
                    );
                  })}
                </SortableContext>
              </DndContext>
            </div>
          </>
        )}

        
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="top-header-edit">
            <h5>Amenities</h5>
          </div>
          <div className="header-action">
            <a
              onClick={(e) => handleShowAmentieModal(e)}
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
            >
              Add New
            </a>
          </div>
        </div>
        <div className="row mt-4 row-gap-4">
          {unselectedAmenities?.map((amenity, index) => (
            <div className="col-xl-4 col-lg-6" key={amenity.amenity_id}>
              <div className="amenity-wrap d-flex align-items-center mb-3 border px-3 py-3 h-100">
                <div className="check-amenity">
                  <input
                    type="checkbox"
                    value={amenity.amenity_id}
                    onChange={() =>
                      handleCheckboxChange(amenity.amenity_id, amenity)
                    }
                    checked={selectedValues?.includes(amenity.amenity_id)}
                    id={amenity.amenity_id}
                    name={`amenities${index}`}
                  />
                </div>
                <div className="amenity-view d-flex px-2">
                  <div className="icon-amenity">
                    <img
                      src={amenity.icon_path}
                      // alt="Cnopied"
                      // title="Cnopied"
                    />
                  </div>
                  {/* <div className="name-amenity px-2">
                      {amenity.amentity_name}
                    </div> */}
                  <div className="name-amenity px-2">
                    <ToolLink
                      title={amenity.amentity_name}
                      id="t-1"
                      className="text-decoration-none"
                    >
                      {amenity.amentity_name}
                    </ToolLink>{" "}
                  </div>
                </div>
                <button
                  onClick={(e) =>
                    handleShowAmentieModal(amenity.amenity_id, e, "edit")
                  }
                  className="btn btn-primary btn-sm btn-theme"
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button
                  variant="info"
                  onClick={(e) => handleAmenityDelete(amenity.amenity_id, e)}
                  className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          ))}

          {errors.amenitiesId && touched.amenitiesId ? (
            <p className="form-error text-danger">{errors.amenitiesId}</p>
          ) : error.amenitiesId ? (
            <p className="form-error text-danger">{error.amenitiesId}</p>
          ) : null}
        </div>
        {/* <!-- /col-2 --> */}
      </div>     

      <AmenityModal
        show={show}
        handleClose={() => {
          setShow(false);
          setAmenityFile("");
        }}
        aminityLoading={aminityLoading}
        handleSubmit={handleSubmit}
        values={values}
        amenityFileData={amenityFileData}
        amenityCropImage={amenityCropImage}
        showCropMediaModal={showCropMediaModal}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        modalTitle={modalTitle}
        modalHeading={modalHeading}
        initialValues={initialValues}
        setValues={setValues}
        setAmenityCropImage={setAmenityCropImage}
      />

     {confirmationModal && (
       <ConfirmationModal
       show={confirmationModal}
       onClose={() => { setConfirmationModal(false); setAminityLoading(false); }}
       setShow={setShow}
       handleSubmit={handleSubmit}
       setAminityLoading={setAminityLoading}
       amenityId={amenityId}
       setForceEdit={setForceEdit}
       initialValues={initialValues}
       setValues={setValues}
     />
      )}
    </>
  );
}

export default AmenitiesTab1;
