import React, { useState, useEffect } from "react";
import Table from "../../components/Table2";
import { getAllPages } from "../dataService/DataService";
import { useMutation, useQuery ,useQueryClient} from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button, Form, Nav, Tab } from "react-bootstrap";
import * as querydata from "../dataService/DataService";
import { useFormik } from "formik";
import * as Yup from "yup";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import AlbumViewSelection from "../manageAlbum/AlbumViewSelection";

export const settingsSchema = Yup.object({
  //metaTitle: Yup.string().required("Please enter title"),
  // metaDescription: Yup.string().required("Please enter description"),
  //slug: Yup.string().matches(/^[a-zA-Z0-9_-]*$/,
  //  'Only alphanumeric characters, hyphens, and underscores are allowed'),
  //.required('Please enter slug'),
  //keyWords: Yup.string().required("Please enter keyword"),
});

export const ogTypesItems = [
  { value: "", placeholder: "select og type" }, //default selected

  { value: "article", placeholder: "article" },
  { value: "website", placeholder: "website" },
  // { value: "video", placeholder: "video" },
  // { value: "song", placeholder: "song" },
  // { value: "profile", placeholder: "profile" },
  // { value: "product", placeholder: "product" },
  // { value: "music", placeholder: "music" },
  // { value: "book", placeholder: "book" },
];



const ManagePages = () => {
  const client=useQueryClient()
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [settingValue, setSettingValue] = useState("");
  const [offerValForBanner, setOfferValBanner] = useState("");
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const closeCropMediaModal = () => setCropMediaModal(false);

  const getAspectRatio = useQuery(
    ["AspectRatio", "og_image_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("og_image_aspect_ratio")
  );
  const navigate = useNavigate();
  const handleNavigateToEdit = (page_id, websiteId) => {
    navigate(`/manage-pages/manage-home-page/${page_id}`, {
      state: { websiteId: websiteId },
    });
  };

  const showCropMediaModal = (propVal) => {
    console.log("function is called");
    if (propVal === "OGIMAGE") {
      setOfferValBanner(propVal);
      setSettingValue(
        getAspectRatio?.data?.data?.setting_value
          ? getAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    setCropMediaModal(true);
  };

  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ogSiteUrlModal, setOgSiteUrlModal] = useState(false);
  const [pid, setPid] = useState(null);

  const handelSiteModal = () => {
    setOgSiteUrlModal(!ogSiteUrlModal);
  };

  const [showSlug, setShowSlug] = useState(true);

  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };
  const handleModal = (pageId, pageCode) => {
    setShowModal(!showModal);
    setPid(pageId);
    if (
      pageCode === "TOCHOME" ||
      pageCode === "ORBHOME" ||
      pageCode === "KHOME" ||
      pageCode === "RHOME" ||
      pageCode === "VHOME" ||
      pageCode === "HOME" ||
      pageCode === "OLMHOME" ||
      pageCode === "OMKHOME" ||
      pageCode === "SHOME"
    ) {
      setShowSlug(false);
    } else {
      setShowSlug(true);
    }
  };
  const SinglePageData = useQuery(["getSinglePageData", pid], () =>
    querydata.getSinglePageData(pid)
  );
  const [ogImagePlaceholer, setOgImage] = useState(null);

  useEffect(() => {
    const imageData = SinglePageData?.data?.data.og_image ?? null;
    if (imageData === "") {
      setOgImage(null);
    } else {
      setOgImage(imageData);
    }
    // console.log("value of the image is  : ",imageData)
  }, [SinglePageData?.data]);

  const initialValues = {
    metaTitle:
      SinglePageData &&
      SinglePageData.data &&
      SinglePageData?.data?.data.meta_title
        ? SinglePageData?.data?.data.meta_title
        : "",
    metaDescription:
      SinglePageData &&
      SinglePageData.data &&
      SinglePageData?.data?.data.meta_description
        ? SinglePageData?.data?.data.meta_description
        : "",
    slug:
      SinglePageData && SinglePageData.data && SinglePageData?.data?.data.slug
        ? SinglePageData?.data?.data.slug
        : "",
    keyWords:
      SinglePageData &&
      SinglePageData.data &&
      SinglePageData?.data?.data.meta_keyword
        ? SinglePageData?.data?.data.meta_keyword
        : "",
    ogTitle:
      SinglePageData &&
      SinglePageData.data &&
      SinglePageData?.data?.data.og_title
        ? SinglePageData?.data?.data.og_title
        : "",
    ogDescription:
      SinglePageData &&
      SinglePageData.data &&
      SinglePageData?.data?.data.og_description
        ? SinglePageData?.data?.data.og_description
        : "",
    ogType:
      SinglePageData &&
      SinglePageData.data &&
      SinglePageData?.data?.data.og_type
        ? SinglePageData?.data?.data.og_type
        : "",
    ogUrl:
      SinglePageData && SinglePageData.data && SinglePageData?.data?.data.og_url
        ? SinglePageData?.data?.data.og_url
        : "",
    // ogImage: ogImagePlaceholer,
    ogSiteName:
      SinglePageData &&
      SinglePageData.data &&
      SinglePageData?.data?.data.og_site_name
        ? SinglePageData?.data?.data.og_site_name
        : "",
  };
  useEffect(() => {
    setValues({ ...initialValues });
  }, [SinglePageData.data]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: settingsSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (typeof ogImagePlaceholer === "string") {
        const fileName = ogImagePlaceholer.split("/").pop(); //getting the file name i.e pop remove the last emement from the array
        formData.append("ogImage", fileName);
      } else {
        formData.append("ogImage", ogImagePlaceholer);
      }
      updateprojectMutation.mutate({ formData, pid });
    },
  });

  const updateprojectMutation = useMutation(querydata.updatePageData, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-pages");
      setShowModal(false);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const columns = React.useMemo(
    () => [
      { Header: "Sr.No", accessor: "website_page_id", style: { width: "1px" } },
      { Header: "Page Name", accessor: "page_title" },

      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                handleNavigateToEdit(
                  row.original.website_page_id,
                  row.original.website_id
                )
              }
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              onClick={() =>
                handleModal(
                  row.original.website_page_id,
                  row.original.page_code
                )
              }
              className="btn btn-primary bg-dark border-dark btn-sm btn-theme ms-1"
            >
              <i className="fa fa-gear"></i>
            </button>
          </>
        ),
      },
    ],
    []
  );
  let allPages = useQuery("getAllPages", getAllPages);

  const initialValuesOgForm = {
    ogSiteName: allPages?.data?.og_site_name,
  };

  const ogsiteNameMutation=useMutation(querydata.updateSiteName,{
    onSuccess:()=>{
      toast.dismiss();
      toast.success("og site name updated successfully ");
      client.invalidateQueries("getAllPages")
      setOgSiteUrlModal(false)
   
      
    },
    onError:(error)=>{
      toast.dismiss();
      toast.error(error?.message)
      client.invalidateQueries("getAllPages")
      setOgSiteUrlModal(false)
   
    }
  })
  const ogForm = useFormik({
    initialValues: initialValuesOgForm,
    onSubmit: () => {
      ogsiteNameMutation.mutate(ogForm.values)

    },
  });
  useEffect(() => {
    ogForm.setValues({ ...initialValuesOgForm });
  }, [allPages?.data?.og_site_name]);

  if (!allPages.data) {
    <div>Loading</div>;
  }
  if (allPages.isLoading) {
    <div>Loading</div>;
  }

  if (!SinglePageData.data) {
    <div>Loading</div>;
  }
  if (SinglePageData.isLoading) {
    <div>Loading</div>;
  }

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <h4 className="fw-normal m-0">Manage Pages</h4>
          </div>
          <div className="card mt-lg-5 mt-4">
            <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0 fw-medium">Manage Pages</h5>
            </div>

            <div className="d-flex justify-content-end align-items-center gap-4  card-body">
              <Button
                variant="primary"
                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                type="button"
                onClick={()=>{setOgSiteUrlModal(true)}}
              >
                Set OG Site
              </Button>
            </div>

            {allPages.data &&
              (allPages.isLoading || !allPages?.data ? (
                <div className="text-center">Loading...</div>
              ) : allPages?.data?.data?.length === 0 ? (
                <div className="card-body text-center">No Data Available</div>
              ) : (
                <>
                  <Table data={allPages?.data?.data} columns={columns} />
                </>
              ))}
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleModal} centered>
        <Modal.Header closeButton className="px-5 py-4">
          <Modal.Title as="h1" className="fs-5">
            Seo Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-5">
          <Tab.Container defaultActiveKey="metaTags">
            <Nav variant="tabs" className="mb-4">
              <Nav.Item>
                <Nav.Link eventKey="metaTags">SLUGS & META TAGS</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ogTags">OG TAGS</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              {/* META TAGS Tab */}
              <Tab.Pane eventKey="metaTags">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="MetaTitle">Meta Title</Form.Label>
                    <Form.Control
                      type="text"
                      id="MetaTitle"
                      className="py-3 shadow-none px-4"
                      name="metaTitle"
                      value={values.metaTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={255}
                    />
                    {errors.metaTitle && touched.metaTitle ? (
                      <p className="form-error text-danger">
                        {errors.metaTitle}
                      </p>
                    ) : error.metaTitle ? (
                      <p className="form-error text-danger">
                        {error.metaTitle}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="MetaDescription">
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      id="MetaDescription"
                      rows="2"
                      className="py-3 shadow-none px-4"
                      name="metaDescription"
                      value={values.metaDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={255}
                    />
                    {errors.metaDescription && touched.metaDescription ? (
                      <p className="form-error text-danger">
                        {errors.metaDescription}
                      </p>
                    ) : error.metaDescription ? (
                      <p className="form-error text-danger">
                        {error.metaDescription}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="MetaSlug">Slug</Form.Label>
                    <Form.Control
                      type="text"
                      id="MetaSlug"
                      className="py-3 shadow-none px-4"
                      name="slug"
                      placeholder={
                        !showSlug ? "Cannot change for homepage" : ""
                      }
                      value={!showSlug ? "" : values.slug}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyPress={handleKeyPress}
                      autoComplete="off"
                      disabled={!showSlug}
                      maxLength={255}
                    />
                    {errors.slug && touched.slug ? (
                      <p className="form-error text-danger">{errors.slug}</p>
                    ) : error.slug ? (
                      <p className="form-error text-danger">{error.slug}</p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="MetaKeywords">Keywords</Form.Label>
                    <Form.Control
                      type="text"
                      id="MetaKeywords"
                      className="py-3 shadow-none px-4"
                      name="keyWords"
                      value={values.keyWords}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.keyWords && touched.keyWords ? (
                      <p className="form-error text-danger">
                        {errors.keyWords}
                      </p>
                    ) : error.keyWords ? (
                      <p className="form-error text-danger">{error.keyWords}</p>
                    ) : null}
                  </Form.Group>
                </Form>
              </Tab.Pane>

              {/* OG TAGS Tab */}
              <Tab.Pane eventKey="ogTags">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="ogTitle">Og Title</Form.Label>
                    <Form.Control
                      type="text"
                      id="ogTitle"
                      className="py-3 shadow-none px-4"
                      name="ogTitle"
                      value={values.ogTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.ogTitle && touched.ogTitle ? (
                      <p className="form-error text-danger">{errors.ogTitle}</p>
                    ) : error.ogTitle ? (
                      <p className="form-error text-danger">{error.ogTitle}</p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="ogDescription">
                      Og Description{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="ogDescription"
                      className="py-3 shadow-none px-4"
                      name="ogDescription"
                      value={values.ogDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.ogDescription && touched.ogDescription ? (
                      <p className="form-error text-danger">
                        {errors.ogDescription}
                      </p>
                    ) : error.ogDescription ? (
                      <p className="form-error text-danger">
                        {error.ogDescription}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="ogType">Og Type </Form.Label>
                    {/* <Form.Control
                      type="text"
                      id="ogType"
                      className="py-3 shadow-none px-4"
                      name="ogType"
                      value={values.ogType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    /> */}

                    <select
                      name="ogType"
                      id="ogType"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ogType}
                      className="py-3 shadow-none px-4 d-block border w-100 text-capitalize  form-select"
                    >
                      {ogTypesItems?.map((item, index) => {
                        return (
                          <option className="" key={index} value={item?.value}>
                            {item?.placeholder}
                          </option>
                        );
                      })}
                    </select>
                    {errors.ogType && touched.ogType ? (
                      <p className="form-error text-danger">{errors.ogType}</p>
                    ) : error.ogType ? (
                      <p className="form-error text-danger">{error.ogType}</p>
                    ) : null}
                  </Form.Group>
                  {/* <Form.Group className="mb-4">
                    <Form.Label htmlFor="ogUrl">Og Url </Form.Label>
                    <Form.Control
                      type="text"
                      id="ogUrl"
                      className="py-3 shadow-none px-4"
                      name="ogUrl"
                      value={values.ogUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.ogUrl && touched.ogUrl ? (
                      <p className="form-error text-danger">{errors.ogUrl}</p>
                    ) : error.ogUrl ? (
                      <p className="form-error text-danger">{error.ogUrl}</p>
                    ) : null}
                  </Form.Group> */}
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="ogImage">Og Image </Form.Label>
                    <div className=" py-3 border rounded">
                      {ogImagePlaceholer === null ? (
                        <label
                          htmlFor="UploadCover"
                          className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5 "
                          onClick={() => showCropMediaModal("OGIMAGE")}
                          tabIndex={-1}
                        >
                          <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                            <button
                              type="button"
                              className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                            >
                              Upload image from gallery
                            </button>
                          </div>
                        </label>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <button
                            className="remove-image-button"
                            onClick={(e) => {
                              e.preventDefault();
                              setOgImage(null);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                          {typeof ogImagePlaceholer === "string" ? (
                            <div
                              className="uploaded-img-view"
                              style={{
                                width: "200px",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={ogImagePlaceholer}
                              />
                            </div>
                          ) : (
                            <div
                              className="uploaded-img-view"
                              style={{
                                width: "200px",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                className=""
                                src={URL.createObjectURL(ogImagePlaceholer)}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {errors.ogImage && touched.ogImage ? (
                      <p className="form-error text-danger">{errors.ogImage}</p>
                    ) : error.ogImage ? (
                      <p className="form-error text-danger">{error.ogImage}</p>
                    ) : null}
                  </Form.Group>
                  {/* <Form.Group className="mb-4">
                    <Form.Label htmlFor="ogSiteName">Og Site Name </Form.Label>
                    <Form.Control
                      type="text"
                      id="ogSiteName"
                      className="py-3 shadow-none px-4"
                      name="ogSiteName"
                      value={values.ogSiteName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.ogSiteName && touched.ogSiteName ? (
                      <p className="form-error text-danger">
                        {errors.keyWords}
                      </p>
                    ) : error.ogSiteName ? (
                      <p className="form-error text-danger">
                        {error.ogSiteName}
                      </p>
                    ) : null}
                  </Form.Group> */}
                </Form>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <div className="text-end">
            <Button
              variant="outline-primary"
              className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for site url  */}
      <Modal show={ogSiteUrlModal} onHide={handelSiteModal} centered>
        <Modal.Header closeButton className="px-5 py-4">
          <Modal.Title as="h1" className="fs-5">
            Og Site Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-5">
          <Form>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="MetaTitle">Og Site Name </Form.Label>
              <Form.Control
                type="text"
                id="MetaTitle"
                className="py-3 shadow-none px-4"
                name="ogSiteName"
                value={ogForm?.values?.ogSiteName}
                onChange={ogForm.handleChange}
                onBlur={ogForm.handleBlur}
                maxLength={255}
              />
            </Form.Group>
          </Form>
          <div className="text-end">
            <Button
              variant="outline-primary"
              className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
              onClick={() => setOgSiteUrlModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
              type="button"
              onClick={() => {
                ogForm.handleSubmit();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewSelection
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              // setmainCroppedImage={setmainCroppedImage}
              setmainCroppedFile={setOgImage}
              // setInnerBannerFile={setInnerBannerFile}
              // setInnerBannerImage={setInnerBannerImage}
              offerValForBanner={offerValForBanner}
              // broucherValue={broucherValue}
              // setBroucherFile={setBroucherFile}
              // setting_value={
              //   getAspectRatio &&
              //   getAspectRatio.data &&
              //   getAspectRatio.data.data &&
              //   getAspectRatio.data.data.setting_value
              // }
              setting_value={settingValue}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManagePages;
