import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

const ImageCropper = () => {
  const [imageSrc, setSrc] = useState(
    'https://images.unsplash.com/photo-1599140849279-1014532882fe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
  );
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };
  
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    // The `croppedArea` contains information about the normalized crop area (values between 0 and 1)
    // The `croppedAreaPixels` contains information about the crop area in pixels

    // You can use the cropped area data as needed
 

    // Update the image source based on the cropped area
    // This is just a simple example, you might want to send this data to the server or handle it differently
    setCroppedAreaPixels(croppedAreaPixels);
  };
  return (
    <div>
      {/* The Cropper component from react-easy-crop */}
      <Cropper
        image={imageSrc}
        crop={crop}
        zoom={zoom}
        aspect={4 / 3}  
        onCropChange={onCropChange}
        onZoomChange={onZoomChange}
        onCropComplete={onCropComplete}
      />
      <img src={crop}></img>
    </div>
  );
};

export default ImageCropper;
