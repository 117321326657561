import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import dragImg from "../../assets/images/drag.svg";

export const Inner = ({
  id,
  subsection,
  handleShowModal,
  handleShowEditSubSectionModal,
  title,
  elementsCount,
  setActiveTab,
  activeTab
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      className="nav-item d-flex align-items-center"
      // className={`nav-item d-flex align-items-center ${isActive ? 'active show' : ''}`}
      role="presentation"
      key={`${subsection?.page_sub_section_id}-tablink`}
      id={`TabLogoOne-tab-${subsection?.id}`}
      data-bs-toggle="tab"
      data-bs-target={`#TabLogoOne-tab-pane-${subsection?.id}`}
      type="button"
      aria-controls={`TabLogoOne-tab-pane-${subsection?.id}`}
      aria-selected="false"
      tabIndex="-1"
      onClick={() => setActiveTab(subsection?.id)}
    >
      {subsection.is_deletable ? (
        <button
          className="drop-drag-btn border-0 bg-transparent"
          {...attributes}
          {...listeners}
        >
          <img src={dragImg} alt="" title="" />
        </button>
      ) : (
        ""
      )}

      <div className="nav-link w-100 position-relative">
        {subsection?.sub_section_title}
        {/* {subsection?.pss_sorting_index} */}
        <div className="position-absolute end-0 top-0 h-100 d-flex align-items-center">
          {subsection?.is_deletable ? (
            <span
              className="text-dark px-2 z-3"
              onClick={(e) => {
                e.stopPropagation(); // Prevent drag action
                handleShowEditSubSectionModal(subsection, subsection?.id);
              }}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </span>
          ) : null}

          {subsection?.is_deletable && elementsCount > 1 ? (
            <span
              className="delete-tab text-dark px-2 pe-3 z-1"
              onClick={(e) => {
                e.stopPropagation(); // Prevent drag action
                handleShowModal(subsection);
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </span>
          ) : null}
        </div>
      </div>
    </li>
  );
};
