import React from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { Dropdown } from "react-bootstrap";
import dragImg from "../../assets/images/drag.svg";
import { CSS } from '@dnd-kit/utilities';

const DraggableDropdownItem = ({ id, option, handelHeading, handleRemoveTab, handleSelectOption }) => {
    const { head_id, collateral_name } = option;

    // Dragging
    const { attributes, listeners, setNodeRef, transform, transition } = useDraggable({ id: head_id.toString() });
    const dragStyle = {
        transition,
        transform: CSS.Transform.toString(transform),
        cursor: 'pointer'
    };
    // Dropping
    const { isOver, setNodeRef: setDropNodeRef } = useDroppable({
        id: id.toString(),
        data: { id },
        disabled: false,
    });

    return (
        <div
            className="d-flex align-items-center dropdown-item"
            onClick={(e) => { handleSelectOption(head_id) }}
            style={{ ...dragStyle }}
        >
            <div onClick={(e) => e.preventDefault()} ref={setDropNodeRef}>
                <button
                    ref={setNodeRef}
                    className="drop-drag-btn border-0 bg-transparent"
                    {...attributes}
                    {...listeners}
                >
                    <img src={dragImg} alt="" title="" />
                </button>
            </div>

            {/* Dropdown Item Content */}
            <div className="nav-link w-100 position-relative">
                <span className='drop-item-name'>{collateral_name}</span>
                <div className="position-absolute end-0 top-0 h-100 d-flex align-items-center">
                    {/* Edit Button */}
                    <span
                        className="text-dark px-2 z-3"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation(); // Prevent drag action
                            handelHeading(e, head_id);
                        }}

                    >

                        <i className="fa fa-pencil" aria-hidden="true"></i>
                    </span>

                    {/* Delete Button */}
                    <span
                        className="delete-tab text-dark px-2 pe-3 z-1"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation(); // Prevent drag action
                            handleRemoveTab(e, head_id); // Pass both the event and head_id
                        }}

                    >
                        <i className="fa fa-trash-can delete-icon text-danger" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default DraggableDropdownItem;
