import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Table from "../../components/Table2";
import { getAllUserRoles,changeUserRoleStatus } from "../dataService/DataService";
import { useMutation, useQuery } from "react-query";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";


const ManageUserRole = () => {


    const navigate = useNavigate();
   
    const handleNavigateToEdit = (id) => {
        navigate(`/manage-roles/edit-user-role/${id}`)
    };
    const handleNavigationPermission = (row) => {
        const id = row.original.user_role_id;

        navigate(`/manage-roles/manage-navigation-permission/${id}`);
    };
    
    const [showModal, setShowModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [selectedRowNameData, setSelectedRowNameData] = useState({});

    const handleShowModal = (rowData) => {
        setSelectedRowData(rowData.original.user_role_id);
        setSelectedRowNameData(rowData.original.user_role);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleStatus = (e, row) => {
        const status = e.target.value;
        const id = row.original.user_role_id;
        row.original.is_active = status;
        const data = changeApplicationStatusMutate.mutate({ id, status });
      };
    
      const changeApplicationStatusMutate = useMutation(changeUserRoleStatus, {
        onError: (error) => {
            toast.dismiss();
          toast.error(error.message);
        },
        onSuccess(data) {
            toast.dismiss();
          toast.success(data.message);
       
        },
      });

    const columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },
            { Header: "User Role", accessor: "user_role" },
            
            {
                Header: "Is Admin",
                Cell: ({ row }) => `${row.original.is_admin === 1 ? "True" : "False"}`,
            },
          
            {
                Header: "Status",
                Cell: ({ row }) => (
                  <select
                    onChange={(e) => handleStatus(e, row)}
                    value={row.original.is_active}
                   
                    className="form-select"
                style={{ width: "110px" }}
                  >
                   
                        <option key="1" value="1" >
                          Active
                        </option>
                        <option key="0" value="0" >
                          Inactive
                        </option>
                    
                  </select>
                ),
              },

            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <>
                        <button
                            onClick={() =>
                                handleNavigateToEdit(row.original.user_role_id)
                            }
                            className="btn btn-primary btn-sm btn-theme"
                        >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                        <button onClick={() => handleNavigationPermission(row)} data-bs-target={`#id${row.user_role_id}`} title="Manage Navigation Permission" className="btn btn-primary bg-dark border-dark btn-sm btn-theme ms-1"><i className="fa-solid fa-gears"></i></button>
                        <button
                            variant="info" onClick={() => handleShowModal(row)}
                            className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
                        >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </>
                ),
            },
        ],
        []
    );

    let allUserRoles = useQuery(
        "getAllUserRoles",
        getAllUserRoles
    );

   


    if (!allUserRoles.data) {
        <div>Loading</div>
    }
    if (allUserRoles.isLoading) {
        <div>Loading</div>
    }
    

    return (
        <>

            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                     
                        <div className="left-header">
                            <h4 className="fw-normal m-0">User Role</h4>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-md-4 mt-3">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 fw-medium">All User Roles</h5>
                                    <div className="right-actions">

                                        <Link to="/manage-roles/create-user-role" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 newuseradd-btn">Add New</Link>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tabledata">
                                        <div className="table-responsive">
                                            {allUserRoles.data &&
                                                <Table data={allUserRoles?.data?.data} columns={columns} />
                                            }
                                            <DeleteModal
                                                show={showModal}
                                                handleClose={handleCloseModal}
                                                rowData={selectedRowData}
                                                rowNameData={selectedRowNameData}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ManageUserRole;
