import React from "react";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";

function PerformingTable({ tableRowData = "" }) {
  const truncateText = (text, limit = 20) => {
    return text.length > limit ? text.slice(0, limit) + "..." : text;
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-borderless mb-0">
          <tbody>
            {tableRowData?.slice(0, 5).map((item, index) => (
              <tr className="bottom-border" key={index}>
                <td className="text-nowrap">
                  <span
                    className="ms-3 smalltxt perfom-texsmall"
                    data-tooltip-id={`tooltip-${index}`}
                  >
                    {truncateText(item.pagePath)}
                  </span>
                  {item.pagePath.length > 20 && (
                    <ReactTooltip
                      id={`tooltip-${index}`}
                      place="top"
                      content={item.pagePath}
                    />
                  )}
                </td>
                <td className="text-nowrap">
                  {item.comparisonPercentage >= 0 ? (
                    <span className={`value-stats increase-value`}>
                      {item.comparisonPercentage + "%"}
                      <IoIosArrowRoundUp className="ms-1 increase-icon" />
                    </span>
                  ) : (
                    <span className={`value-stats decrease-value`}>
                      {-item.comparisonPercentage + "%"}
                      <IoIosArrowRoundDown className="ms-1 decrease-icon" />
                    </span>
                  )}
                </td>
                <td>{item.pageviews}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default PerformingTable;