import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle } from "./DragHandle";
import styled from "styled-components";
import dragDropImg from "../../assets/images/drag.svg";
const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

// const TableData = styled.td`
//   background: white;
//   &:first-of-type {
//     min-width: 20ch;
//   }
// `;
const TableData = styled.td`
  background: white;
  min-width: 80px; /* Example width */
  height: 50px; /* Example height */
  padding: 1px; /* Adjust padding as needed */
`;
export const DraggableTableRow = ({ row, componentName }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.original.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };
  const websiteName = getCookie("websiteName");


  return (
    <tr ref={setNodeRef} style={style} {...row.getRowProps()}>
      {isDragging ? (
        <React.Fragment>
          <DraggingRow colSpan={row.cells.length}>
            <img src={dragDropImg} alt="" title="" />
          </DraggingRow>
          <td></td>
        </React.Fragment>
      ) : (
        row.cells.map((cell, i) => {
          if (i === 0) {
            return (
              <TableData {...cell.getCellProps()}>
                {componentName === "pressrelease" ? (
                  decodeURIComponent(websiteName) == "AHR Brand Website" || decodeURIComponent(websiteName)=="Ozen Collection"? (
                    <DragHandle {...attributes} {...listeners} />
                  ) : (
                    ""
                  )
                ) : (
                  <DragHandle {...attributes} {...listeners} />
                )}

                <span>
                  {/* {cell.render("Cell")} */}
                  {/* {i === 0 ? row.index + 1 : cell.render("Cell")} */}
                  {row.index + 1}
                </span>
              </TableData>
            );
          }
          return (
            <TableData {...cell.getCellProps()}>
              {cell.render("Cell")}

              {/* {i === 0 ? row.index + 1 : cell.render("Cell")} */}
            </TableData>
          );
        })
      )}
    </tr>
  );
};
