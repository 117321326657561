
import React, { useState } from "react";
import { Link, useNavigate,useParams } from "react-router-dom";
import Cookies from "js-cookie";



const Welcome = () => {

    const { id } = useParams();
    
    return (
        <>

            <div className="page-body">


            </div>

        </>
    );
};

export default Welcome;
