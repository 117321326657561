import React from 'react'
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';

function PerformingCountries({tableRowItems=''}) {

  return (
    <>
        <div className='table-responsive'>
            <table className='table table-borderless mb-0'>
                <tbody>
                    {tableRowItems?.slice(0,5).map((item, index) => (
            <tr className='bottom-border' key={index}>
                <td className='text-nowrap' > <span className='ms-3 smalltxt'>{item.country}</span></td>
                <td className='text-nowrap' >
                {item.comparisonPercentage>=0 ?  <span className={`value-stats increase-value`}>
                                {item.comparisonPercentage+"%"}
                                {item.comparisonPercentage>=0 ? (
                                <IoIosArrowRoundUp className='ms-1 increase-icon' />
                                ) : (
                                <IoIosArrowRoundDown className='ms-1 decrease-icon' />
                                )}
                            </span> :

                            <span className={`value-stats decrease-value`}>
                                {-item.comparisonPercentage+"%"}
                                {item.comparisonPercentage>=0 ? (
                                <IoIosArrowRoundUp className='ms-1 increase-icon' />
                                ) : (
                                <IoIosArrowRoundDown className='ms-1 decrease-icon' />
                                )}
                            </span> }

                        </td>
                <td>{item.users}</td>
            </tr>
        ))}
                {/* {tableRowItems.map((item, rowIndex) => (
                    <tr className='bottom-border' key={rowIndex}>
                    {item.tableWithOutThead.map((data, cellIndex) => (
                        data.withIconOrWithout ? (
                        <td className='text-nowrap' key={cellIndex}><img src={data.cntryFlg} alt=''/> <span className='ms-3 smalltxt'>{data.cntryName}</span></td>
                        ) : (
                        <td className='text-nowrap' key={cellIndex}>
                            <span className={`value-stats ${data.iconClass}`}>
                                {data.tableHeading}
                                {data.increaseDecreaseIcon ? (
                                <IoIosArrowRoundUp className='ms-1 increase-icon' />
                                ) : (
                                <IoIosArrowRoundDown className='ms-1 decrease-icon' />
                                )}
                            </span>
                        </td>
                        )
                    ))}
                    </tr>
                ))} */}
                </tbody>
            </table>
        </div>
    </>
  )
}

export default PerformingCountries