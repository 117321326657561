import { useEffect, useState } from "react";
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    closestCorners,
} from "@dnd-kit/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import CollateralDropDown from "./CollateralDropDown";

function DropdownWithDragDrop({ handleSelectOption, allCollaterals, setSelectedCollateral, handleRemoveTab, handelHeading, selectedCollateral, headData, changeSortingIndexMutation, handleReorderSubSections, open, setOpen }) {
    // useEffect(() => {
    //     if (allCollaterals) {
    //         setTasks(allCollaterals?.data?.data);
    //     }
    // }, [allCollaterals]);
    const queryClient = useQueryClient();
    const [tasks, setTasks] = useState(allCollaterals?.data?.data || []);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const getTaskPos = (id) => {

        return allCollaterals?.data?.data?.findIndex((task) => task?.head_id === parseInt(id));
    }

    const invalidateQuery = "";

    const handleDragEnd = (event) => {

        const { active, over } = event;

        if (active.id === over.id) return;

        setTasks((tasks) => {
            const originalPos = getTaskPos(active.id);
            const newPos = getTaskPos(over.id);

            handleReorderSubSections(originalPos, newPos);
            changeSortingIndexMutation.mutate({
                collateralsHeadId: active.id,
                newSortingIndex: newPos + 1,
            }
            );

            return arrayMove(tasks, originalPos, newPos);
        });
    };
    const items = allCollaterals?.data?.data?.map(item => ({ id: item.head_id }));

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}

            onDragEnd={handleDragEnd}
        >
            <CollateralDropDown
                id={allCollaterals?.data?.data?.map((item) => item.head_id)}
                items={items}
                tasks={tasks}
                handelHeading={handelHeading}
                handleSelectOption={handleSelectOption}
                selectedCollateral={selectedCollateral}
                headData={headData}
                handleRemoveTab={handleRemoveTab}
                allCollaterals={allCollaterals}
                open={open}
                setOpen={setOpen}
            />

        </DndContext>
    )
}

export default DropdownWithDragDrop