import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryClient } from "react-query";
import { Modal, Button, Form, Nav, Tab } from "react-bootstrap";
import { useMutation } from "react-query";
import { editScript, insertScript } from "../../dataService/DataService";
import { toast } from "react-toastify";

const scriptSchema = Yup.object({
  name: Yup.string().trim().required("Please enter script name ").min(2,"script name must be greater than 2 character"),
  value: Yup.string().trim().required("Please enter scrit Value ").min(8,"enter a valid script "),
  location_id: Yup.string().trim(),
});
export const ScriptModal = ({
  setModal,
  modal,
  action = "Add Script",
  initialData,
  location_id,
}) => {
  const client = useQueryClient();
  const initialValues = {
    name: initialData?.name || "",
    value: initialData?.value || "",
    location_id: initialData?.location_id || location_id,
  };
  const editMutation = useMutation(editScript, {
    onSuccess: () => {
      toast.dismiss();
      toast.success("updated successfully ");
      setModal(false);
      client.invalidateQueries("scripts");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.message || "something went wrong");
    },
  });
  const insertMutation = useMutation(insertScript, {
    onSuccess: () => {
      toast.dismiss();
      toast.success("updated successfully ");
      setModal(false);
      client.invalidateQueries("scripts");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.message || "something went wrong");
    },
  });

  const { values, handleChange, errors, handleSubmit,validateOnBlur,handleBlur,touched } = useFormik({
    initialValues,
    validationSchema: scriptSchema,
    onSubmit: () => {
      if (initialData) {
        //case for updating the script
        if (!initialData?.id) {
          toast.dismiss();
          toast.error("invalid id ");
          return;
        }
        editMutation.mutate({ id: initialData?.id, playLoad: values });
        return;
      }
      insertMutation.mutate({ playLoad: values,gtm:false });
    },
  });
  return (
    <Modal
      show={modal}
      onHide={() => {
        setModal(false);
      }}
      centered
    >
      <Modal.Header closeButton className="px-5 py-4">
        <Modal.Title as="h1" className="fs-5">
          {action}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 py-5">
        <Form>
          <Form.Group className="mb-4">
            <Form.Label htmlFor="MetaTitle">Script Name</Form.Label>
            <Form.Control
              type="text"
              id="MetaTitle"
              value={values?.name}
              className="py-3 shadow-none px-4"
              name="name"
              onChange={handleChange}
              maxLength={100}
            />
            {
              errors.name && touched.name && (
                <p className="text-danger">{errors.name} </p>
              )
            }
            <Form.Label htmlFor="MetaDescription">Script</Form.Label>
            <Form.Control
              as="textarea"
              id="MetaDescription"
              rows="3"
              value={values?.value}
              onChange={handleChange}
              className="py-3 shadow-none px-4"
              name="value"
            />
            {
              errors.value &&touched.value &&  (
                <p className="text-danger">{errors.value} </p>
              )
            }
          </Form.Group>
          <div className="text-end">
            <Button
              variant="outline-primary"
              className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
              onClick={()=>{setModal(false);}}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
              type="button"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
