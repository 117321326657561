import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {useNavigate, useParams } from "react-router-dom";
import * as querydata from "../dataService/DataService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// Login Validation
export const loginSchema = Yup.object({
  password: Yup.string()
    .required("Please enter new password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?~`[\]\\';,./]).{8,}$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character, and be at least 8 characters long"
    ),
  cPassword: Yup.string().required("Please enter above password").oneOf([Yup.ref("password"), null], "Passwords must match"),
});
const ResetPassword = () => {
  // Initialize Navigate method
  const navigate = useNavigate();

  // Values defined for Formik
  const initialValues = {
    password: "",
    cPassword: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword,setShowCPassword]=useState(false);
  const { token } = useParams();


  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const toggleShowCPassword=(e)=>{
    e.preventDefault();
    setShowCPassword(!showCPassword);
  }

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: (values, action) => {
      
        resetPasswordMutation.mutate({ ...values, token: token });
        action.resetForm();
      },
    });

  // Form post
  const resetPasswordMutation = useMutation(querydata.resetPassword, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success("New password has been created successfully");
      navigate("/");
    },
  });

  return (
    <>
      <div className="login-wrapper position-relative d-flex align-items-center">
        <form className="w-100 m-auto rounded-2 position-relative z-2 bg-white pb-4">
          <div className="login-logo px-3 py-4 text-center">
            <img
              src="../../assets/images/logo-circle.svg"
              alt="Atmosphere"
              title="Atmosphere"
            />
            <h4 className="mt-4">Reset Password</h4>
          </div>
          <div className="login-fields px-lg-5 px-4">
            <div className="position-relative mb-4">
              <input
              maxLength={20}
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                id="password"
                className="form-control shadow-none"
              />
              <span className="showpassword position-absolute" onClick={toggleShowPassword}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
   
              {errors.password && touched.password && (
                <div className="text-danger">{errors.password}</div>
              )}
            </div>
            <div className="position-relative mb-4">
              <input
              maxLength={20}
                type={showCPassword ? "text" : "password"}
                placeholder=" Confirm Password"
                name="cPassword"
                value={values.cPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                id="cPassword"
                className="form-control shadow-none"
              />
              <span className="showpassword position-absolute" onClick={toggleShowCPassword}>
                <FontAwesomeIcon icon={showCPassword ? faEyeSlash : faEye} />
              </span>
              {errors.cPassword && touched.cPassword && (
                <div className="text-danger">{errors.cPassword}</div>
              )}
           
            </div>
            <div className="mb-4">
              {resetPasswordMutation.isLoading ? (
                <button
                  className="ms-auto btn btn-primary btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-login-btn"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden" role="status">
                    Loading...
                  </span>
                </button>
              ) : (
                <button
                  id="login-btn"
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-3 w-100"
                >
                  Reset
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
