import React from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Table from "../../components/Table2";
import { useQuery } from "react-query";
import * as querydata from "../dataService/DataService";
import backarrow from "../../assets/images/back-arrow.svg";

function ManageHomePage() {
  const navigate = useNavigate();
  const { pageId } = useParams();
  const location=useLocation();
  const handleNavigateToEdit = (page_section_id,page,section) => {
    navigate(`/manage-pages/edit-page/${pageId}/${page_section_id}`);
    navigate(`/manage-pages/edit-page/${pageId}/${page_section_id}`,{ state: {page:page,section:section ,websiteId:location?.state?.websiteId} });
  };

  const columns = React.useMemo(
    () => [
      { Header: "Sr.No", accessor: "page_section_id" },
      { Header: "Sections", accessor: "section_title" },
    
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => handleNavigateToEdit(row.original.page_section_id)}
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
          
          </>
        ),
      },
    ],
    []
  );

  const Section = useQuery(["getSectionByPageId", +pageId], () =>
    querydata.getSectionByPageId(pageId)
  );
  if (!Section.data) {
    <div>Loading</div>;
  }
  if (Section.isLoading) {
    <div>Loading</div>;
  }
 
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-pages">
                    Manage Pages
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Manage {Section?.data?.data?.[0]?.page_title}
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to="/manage-pages"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">
                Manage {Section?.data?.data?.[0]?.page_title}
              </h4>
            </div>
          </div>
          <div className="card mt-lg-5 mt-4">
            <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0 fw-medium">Manage {Section?.data?.data?.[0]?.page_title}</h5>
            </div>
            {Section.data && (              
              Section.isLoading || !Section?.data ? (
                        <div className="text-center">Loading...</div>
                      ) : Section?.data?.data?.length === 0 ? (
                        <div className="card-body text-center">No Data Available</div>
                      ) : (
              <>
              <Table data={Section?.data?.data} columns={columns} />
              </>
            ))}
           </div>
        </div>
      </div>
    </>
  );
}

export default ManageHomePage;
